<template>
  <b-container fluid>
    <ResourceList
      can-delete create-to="dash.reminders.create" resource="reminder" soft-deletes title="Reminders"
      :data="data" :is-deleting="isDeleting" :is-loading="isLoading" :pagination="pagination"
      :search-query="$route.query.query" @delete-many="onDeleteMany" @paginate="onPaginate"
      @search="onSearch">

      <template #custom-content>
        <b-button-group class="w-100">
          <b-button :variant="filterSelect == 'Due Today' ? 'primary' : 'outline-primary'" @click="filterClicked('Due Today')">Due Today</b-button>
          <b-button :variant="filterSelect == 'Overdue' ? 'primary' : 'outline-primary'" @click="filterClicked('Overdue')">Overdue</b-button>
          <b-button :variant="filterSelect == 'Upcoming' ? 'primary' : 'outline-primary'" @click="filterClicked('Upcoming')">Upcoming</b-button>
          <b-button :variant="filterSelect == 'Complete' ? 'primary' : 'outline-primary'" @click="filterClicked('Complete')">Complete</b-button>
        </b-button-group>
        <div class="mt-3">
          <b-form-checkbox v-model="showOthersReminders" @change="doPaginate()">Show reminders that belong to other people</b-form-checkbox>
        </div>
      </template>

      <template #listItem="{ item }">
        <div class="d-flex flex-column flex-fill">
          <div class="d-flex flex-row flex-fill">
            <div class="d-flex flex-column flex-fill">
              <p class="h5 flex-fill mb-0 text-primary"><router-link class="text-dark" :to="{name: 'dash.customers.view', params: { id: item.customer_id }}">{{ item.customer.forename }} {{ item.customer.surname }}</router-link></p>
              <small class="text-muted">{{ returnMomentDue(item).format('LLL') }}</small>
            </div>
            <div class="d-flex flex-column align-items-end">
              <small v-if="item.completed_at" class="text-muted">Completed At: {{ moment(item.completed_at).format('DD/MM/YYYY') }}</small>
              <small v-if="showOthersReminders && item.user" class="text-muted">Reminder For: {{ item.user.name }}</small>
            </div>
          </div>
          <p class="mb-0">{{ item.note }}</p> 
          <div v-if="item.customer_note">
            <hr>
            <h6>Attached Note</h6>
            <div>
              {{ item.customer_note.note }}
            </div>
            <hr class="mt-3 mb-0">
          </div>
          <div class="mt-2">
            <b-button variant="secondary" class="mr-2" size="sm" @click="doMarkComplete(item.id)" v-if="!item.completed_at">Mark as Complete</b-button>
            <b-button variant="primary" size="sm" :to="{name: 'dash.reminders.update', params: { id: item.id }}" v-if="!item.completed_at">Edit Reminder</b-button>
          </div>
        </div>
      </template>

    </ResourceList>
  </b-container>
</template>

<script>
import FriendlyDate from '../../components/FriendlyDate'
import momentMixin from '../../mixins/momentMixin'
import ResourceList from '../../components/ResourceList'
import currentUser from '../../mixins/currentUser'
import { mapActions, mapGetters } from 'vuex'

export default {
  mixins: [currentUser, momentMixin],
  created () {
    this.fetchMany({
      ...this.$route.query,
      extraParams: {
        'filter': this.filterSelect,
        'showOthersReminders': this.showOthersReminders,
      }
    })
  },
  data: () => ({
    filterSelect: 'Due Today',
    showOthersReminders: false,
  }),
  components: {
    FriendlyDate,
    ResourceList
  },
  computed: {
    ...mapGetters('reminders', ['data', 'isDeleting', 'isLoading', 'pagination'])
  },
  methods: {
    ...mapActions('reminders', ['deleteMany', 'fetchMany', 'markComplete']),
    doMarkComplete(reminder) {
      this.markComplete(reminder).then(() => {
        this.doPaginate()
      })
    },
    async onDeleteMany ({ ids, modalId }) {
      await this.deleteMany({ ids })
      this.$bvModal.hide(modalId)
      await this.fetchMany({
          ...this.$route.query,
          extraParams: {
            'filter': this.filterSelect,
          },
      })
    },
    async onPaginate (page) {
      if (this.$route.query.page !== page) {
        this.$router.push({ name: this.$route.name, query: { ...this.$route.query, page } })
        await this.fetchMany({
          ...this.$route.query,
          page,
          extraParams: {
            'filter': this.filterSelect,
            'showOthersReminders': this.showOthersReminders,
          },
        })
      }
    },
    async doPaginate () {
        await this.fetchMany({
          ...this.$route.query,
          extraParams: {
            'filter': this.filterSelect,
            'showOthersReminders': this.showOthersReminders,
          },
        })
    },
    async onSearch (query) {
      if (this.$route.query.query !== query) {
        this.$router.push({ name: this.$route.name, query: { ...this.$route.query, query } })
        await this.fetchMany({
          ...this.$route.query,
          query,
          extraParams: {
            'filter': this.filterSelect,
            'showOthersReminders': this.showOthersReminders,
          },
        })
      }
    },

    returnMomentDue(item) {
      return this.moment( item.date +' '+ item.time )
    },

    filterClicked(value) {
      if(value == this.filterSelect) this.filterSelect = null
      else this.filterSelect = value

      this.doPaginate()
    },
  }
}
</script>

<style>
</style>
