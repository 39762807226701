import pagination from '../../utils/pagination'
import {
  CLOCKIFY_TIMESHEETS_FETCH_MANY_REQUEST, CLOCKIFY_TIMESHEETS_FETCH_MANY_SUCCESS, CLOCKIFY_TIMESHEETS_FETCH_MANY_FAILURE,

  EMPTY_CLOCKIFY_TIMESHEETS,
  CLEAR_ERRORS
} from './types'

export default {
  [CLOCKIFY_TIMESHEETS_FETCH_MANY_REQUEST] (state) {
    state.data = []
    state.loading = true
  },
  [CLOCKIFY_TIMESHEETS_FETCH_MANY_SUCCESS] (state, { data }) {
    state.data = data.data
    state.page = pagination(data)
    state.loading = false
  },
  [CLOCKIFY_TIMESHEETS_FETCH_MANY_FAILURE] (state, { validationErrors }) {
    state.validationErrors = validationErrors || {}
    state.loading = false
  },
  [EMPTY_CLOCKIFY_TIMESHEETS] (state) {
    state.data = []
    state.loading = false
    state.saving = false
  },
  [CLEAR_ERRORS] (state) {
    state.validationErrors = []
    state.loading = false
    state.saving = false
  },
}
