export const REMINDERS_BATCH_DELETE_REQUEST = 'REMINDERS_BATCH_DELETE_REQUEST'
export const REMINDERS_BATCH_DELETE_SUCCESS = 'REMINDERS_BATCH_DELETE_SUCCESS'
export const REMINDERS_BATCH_DELETE_FAILURE = 'REMINDERS_BATCH_DELETE_FAILURE'

export const REMINDERS_FETCH_MANY_REQUEST = 'REMINDERS_FETCH_MANY_REQUEST'
export const REMINDERS_FETCH_MANY_SUCCESS = 'REMINDERS_FETCH_MANY_SUCCESS'
export const REMINDERS_FETCH_MANY_FAILURE = 'REMINDERS_FETCH_MANY_FAILURE'

export const REMINDERS_FETCH_REQUEST = 'REMINDERS_FETCH_REQUEST'
export const REMINDERS_FETCH_SUCCESS = 'REMINDERS_FETCH_SUCCESS'
export const REMINDERS_FETCH_FAILURE = 'REMINDERS_FETCH_FAILURE'

export const REMINDERS_SAVE_REQUEST = 'REMINDERS_SAVE_REQUEST'
export const REMINDERS_SAVE_SUCCESS = 'REMINDERS_SAVE_SUCCESS'
export const REMINDERS_SAVE_FAILURE = 'REMINDERS_SAVE_FAILURE'

export const EMPTY_REMINDERS = 'EMPTY_REMINDERS'
export const CLEAR_ERRORS = 'CLEAR_ERRORS'