<template>
  <b-container fluid>
    <b-form @submit.prevent="submit">
      <b-row class="mb-3">
        <b-col cols="7">
          <h1 class="font-weight-bold text-primary">{{ $route.params.id ? 'Update' : 'Create' }} Reminder</h1>
        </b-col>
        <b-col cols="5">
          <b-button class="float-right" :to="{name: 'dash.customers.view', params: { id: reminder.customer.id }}" v-if="$route.params.id && reminder.customer.id">
            View Customer
          </b-button>
        </b-col>
      </b-row>
      <b-row v-if="isLoading">
        <b-col cols="12">
          <p class="text-center"><b-spinner variant="secondary"></b-spinner></p>
        </b-col>
      </b-row>
      <b-row v-if="!isLoading">
        <b-col cols="12">
          <b-row class="mb-4">
            <b-col cols="12">
              <b-card>
                <div class="d-flex flex-column flex-md-row justify-content-between">
                  <div class="d-flex flex-column justify-content-center">
                    <p class="h3 font-weight-bold text-muted">Customer</p>
                    <p class="mb-0 text-muted">Who is this reminder for?</p>
                  </div>
                  <div class="d-flex flex-column flex-lg-row justify-content-center mt-3 mt-md-0">
                    <customer-search :error-feed="errors.errors" :error-key="'customer_id'" class="align-self-lg-center mb-2 mb-lg-0 mr-lg-1 text-nowrap w-100" type="customer" @input="customerUpdated" v-model="reminder.customer" />
                  </div>
                </div>
              </b-card>
            </b-col>
          </b-row>
          <b-row class="mb-4">
            <b-col cols="12">
              <b-card>
                <b-row>
                  <b-col cols="12" class="d-flex flex-column flex-fill justify-content-center">
                      <p class="h4 font-weight-bold mb-0">Reminder Details</p>
                  </b-col>
                </b-row>
                <b-row class="mt-4">
                  <b-col cols="12">
                    <b-row>
                      <b-col md="6">
                        <b-form-group label="Date" :invalid-feedback="validationInvalidFeedback(errors.errors, 'date')">
                          <b-form-datepicker :state="validationState(errors.errors, 'date')" v-model="reminder.date" />
                        </b-form-group>
                      </b-col>
                      <b-col md="6">
                        <b-form-group label="Time" :invalid-feedback="validationInvalidFeedback(errors.errors, 'time')">
                          <b-form-timepicker :disabled="isSaving" :state="validationState(errors.errors, 'time')" v-model="reminder.time" />
                        </b-form-group>
                      </b-col>
                    </b-row>
                  </b-col>
                  <b-col cols="12">
                    <b-form-group label="Note" :invalid-feedback="validationInvalidFeedback(errors.errors, 'note')">
                      <b-form-textarea :disabled="isSaving" :state="validationState(errors.errors, 'note')" v-model="reminder.note" />
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-card>
            </b-col>
          </b-row>
          <b-row class="mb-4">
            <b-col cols="6" md="3" offset-md="9">
              <b-button block type="submit" variant="secondary"><b-spinner small v-if="isSaving"></b-spinner><span v-if="!isSaving">Save</span></b-button>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-form>
  </b-container>
</template>

<script>
import validation from '../../mixins/validation'
import momentMixin from '../../mixins/momentMixin'
import { mapActions, mapGetters } from 'vuex'
import CustomerSearch from '../../components/CustomerSearch'

export default {
  mixins: [validation, momentMixin],
  components: { CustomerSearch },
  created () {
    if (this.$route.params.id) {
      this.fetch(this.$route.params.id)
    }else{
      if(this.$route.params.customer_id) {
        this.reminder.customer_id = this.$route.params.customer_id
        this.reminder.customer.id = this.$route.params.customer_id
      }
    }
  },
  computed: {
    ...mapGetters('reminders', ['errors', 'isLoading', 'isSaving', 'single']),
  },
  data () {
    return {
      reminder: {
        customer_id: null,
        customer: {
          id: null,
        },
        date: null,
        time: null,
        note: null
      },
    }
  },
  methods: {
    ...mapActions('reminders', ['fetch', 'save']),

    customerUpdated() {
      this.reminder.customer_id = this.reminder.customer.id
    },

    submit () {

      let data = {
        id: this.$route.params.id,
        customer_id: this.reminder.customer.id,
        date: this.moment(this.reminder.date).format('DD/MM/YYYY'),
        time: this.moment(this.reminder.time, 'HH:mm:ss').format('HH:mm'),
        note: this.reminder.note,
      }

      this.save(data).then(response => {
        if([ 200, 201 ].includes(response.status)) this.$router.push({
          name: 'dash.reminders.list',
          params: {
            notifications: [{
              variant: 'success',
              title: `Reminder #${response.data.id}`,
              text: `The reminder has been ${this.$route.params.id ? 'updated' : 'created'} successfully.`,
              timeout: 5000
            }]
          }
        })
      })
    },
  },
  watch: {
    single (newReminder) {
      this.reminder = this.single
    }
  }
}
</script>

<style>

</style>
