<template>
  <router-view />
</template>

<script>
import timesheetModule from '../../store/timesheets'
import userModule from '../../store/users'

export default {
  beforeCreate () {
    this.$store.registerModule('timesheets', timesheetModule)
    this.$store.registerModule('users', userModule)
  },
  destroyed () {
    this.$store.unregisterModule('timesheets')
    this.$store.unregisterModule('users')
  }
}
</script>
