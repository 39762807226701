import {
  TIMESHEETS_FETCH_MANY_REQUEST,
  TIMESHEETS_FETCH_MANY_SUCCESS,
  TIMESHEETS_FETCH_MANY_FAILURE,

  TIMESHEETS_SAVE_REQUEST,
  TIMESHEETS_SAVE_SUCCESS,
  TIMESHEETS_SAVE_FAILURE,
  CLEAR_ERRORS
} from './types'

export default {
  fetchMany ({ commit }, { query = '', page = 1, extraParams }) {
    commit(TIMESHEETS_FETCH_MANY_REQUEST)
    return window.axios.get('/timesheets', { params: { query, page, ...extraParams } }).then(response => {
      commit(TIMESHEETS_FETCH_MANY_SUCCESS, response)
      return response
    }).catch(error => {
      commit(TIMESHEETS_FETCH_MANY_FAILURE, error)
      throw error
    })
  },
  save ({ commit }, data) {
    commit(TIMESHEETS_SAVE_REQUEST)
    return window.axios.post('timesheets/log-timesheet', data).then(response => {
      commit(TIMESHEETS_SAVE_SUCCESS, response)
      return response
    }).catch(error => {
      if (error.response.status === 422) {
        commit(TIMESHEETS_SAVE_FAILURE, { validationErrors: error.response.data })
      } else {
        commit(TIMESHEETS_SAVE_FAILURE, { error })
      }
      throw error
    })
  },
  empty({ commit }) {
    commit(EMPTY_TIMESHEETS);
  },
  clearErrors({ commit }) {
    commit(CLEAR_ERRORS)
  }
}
