<template>
  <b-container fluid>
    <b-form @submit.prevent="submit">
      <b-row class="mb-3">
        <b-col cols="12">
          <h1 class="font-weight-bold text-primary">{{ $route.params.id ? 'Update' : 'Create' }} User</h1>
        </b-col>
      </b-row>
      <b-row v-if="isLoading">
        <b-col cols="12">
          <p class="text-center"><b-spinner variant="secondary"></b-spinner></p>
        </b-col>
      </b-row>
      <b-row v-if="!isLoading">
        <b-col cols="12">
          <b-row>
            <b-col cols="12">
              <b-form-group label="Name" :invalid-feedback="validationInvalidFeedback(errors.errors, 'name')">
                <b-form-input :disabled="isSaving" :state="validationState(errors.errors, 'name')" v-model="user.name" />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12">
              <b-form-group label="Email Address" :invalid-feedback="validationInvalidFeedback(errors.errors, 'email')">
                <b-form-input :disabled="isSaving" type="email" :state="validationState(errors.errors, 'email')" v-model="user.email" />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12" md="6">
              <b-form-group label="Password" :invalid-feedback="validationInvalidFeedback(errors.errors, 'password')">
                <template #label>
                  Password <b-icon class="text-secondary" icon="info-square-fill" title="Password Strength Requirements" v-b-popover.hover.righttop="'A password must be a strong password. Please enter at least 8 characters, with at least one number and one uppercase character.'"></b-icon>
                </template>
                <b-form-input :disabled="isSaving" type="password" :state="validationState(errors.errors, 'password')" v-model="user.password" />
              </b-form-group>
            </b-col>
            <b-col cols="12" md="6">
              <b-form-group label="Confirm Password" :invalid-feedback="validationInvalidFeedback(errors.errors, 'password_confirmation')">
                <b-form-input :disabled="isSaving" type="password" :state="validationState(errors.errors, 'password_confirmation')" v-model="user.password_confirmation" />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12">
              <b-form-group label="Role" :invalid-feedback="validationInvalidFeedback(errors.errors, 'role_id')">
                <b-form-select :disabled="isSaving" :state="validationState(errors.errors, 'role_id')" v-model="roleId">
                  <b-form-select-option :value="null">Select a role</b-form-select-option>
                  <b-form-select-option :value="role.id" v-for="(role, roleInd) in roles" :key="`role${roleInd}`">{{ role.name }}</b-form-select-option>
                </b-form-select>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12">
              <b-form-group label="Showroom" :invalid-feedback="validationInvalidFeedback(errors.errors, 'showroom_id')">
                <b-form-select :disabled="isSaving" :state="validationState(errors.errors, 'showroom_id')" v-model="user.showroom_id">
                  <b-form-select-option :value="null">Select a showroom</b-form-select-option>
                  <b-form-select-option :value="showroom.id" v-for="(showroom, showroomInd) in showrooms" :key="`showroom${showroomInd}`">{{ showroom.name }}</b-form-select-option>
                </b-form-select>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12">
              <b-form-group label="Clockify Username" :invalid-feedback="validationInvalidFeedback(errors.errors, 'clockify_id')">
                <b-form-select :disabled="isSaving" :state="validationState(errors.errors, 'clockify_id')" v-model="user.clockify_id">
                  <b-form-select-option :value="null">Select a clockify username</b-form-select-option>
                  <b-form-select-option :value="clockifyUser.user_id" v-for="(clockifyUser, clockifyUserInd) in clockifyUsers" :key="`clockifyUser${clockifyUserInd}`">{{ clockifyUser.clockify_name }}</b-form-select-option>
                </b-form-select>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12">
              <b-card no-body class="p-3 mb-3">
                <b-row>
                  <b-col cols="12">
                    <div class="d-flex flex-row">
                      <h4 class="flex-fill">Gmail Password</h4>
                      <b-button
                        :variant="rmGmailPassword ? 'outline-success' : 'outline-danger'"
                        v-if="user.has_gmail_password"
                        @click="rmGmailPassword = !rmGmailPassword"
                      >{{ rmGmailPassword ? 'Keep Existing Gmail Password' : 'Remove Existing Gmail Password'}}</b-button>
                    </div>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col cols="12">
                    <hr class="mt-2">
                  </b-col>
                </b-row>
                <b-row>
                  <b-col cols="12">
                    <b-form-group label="Gmail Password" :invalid-feedback="validationInvalidFeedback(errors.errors, 'gmail_password')">
                      <b-form-input :disabled="isSaving || rmGmailPassword" :state="validationState(errors.errors, 'gmail_password')" v-model="gmailPassword" />
                    </b-form-group>
                    <hr v-if="user.has_gmail_password">
                    <div class="d-flex flex-column">
                      <small class="text-muted" v-if="user.has_gmail_password">To remove an existing password without storing a new one, click the Remove Existing Gmail Password button.</small>
                      <small class="text-muted" v-if="user.has_gmail_password">To change the password, you can simply type a new password in. If you would like to make no changes, leave this field empty.</small>
                    </div>
                  </b-col>
                </b-row>
              </b-card>
            </b-col>
          </b-row>
          <b-row class="mb-3">
            <b-col cols="12">
              <label>Content: </label>
              <Quill @delta="delta => user.signature_delta_content = delta" :delta-content="user.signature_delta_content" v-model="user.signature_content"/>
            </b-col>
          </b-row>
          <b-row class="mb-4">
            <b-col cols="12">
              <b-card>
                <b-row>
                  <b-col class="d-flex flex-row" cols="12" @click.prevent="toggleCollapse('voip_settings')" role="button">
                    <b-icon class="align-self-center cursor-pointer text-muted" font-scale="1.5" :icon="collapsed['voip_settings'] ? 'chevron-right' : 'chevron-down'"></b-icon>
                    <div class="d-flex flex-column flex-fill ml-4">
                      <p class="h4 font-weight-bold mb-0">VOIP Settings</p>
                    </div>
                  </b-col>
                </b-row>
                <b-row class="mt-4" v-if="!collapsed['voip_settings']">
                  <b-col>
                    <b-form-group label="VOIP Extension" :invalid-feedback="validationInvalidFeedback(errors.errors, 'voip_extension')">
                      <b-form-input :disabled="isSaving" type="text" :state="validationState(errors.errors, 'voip_extension')" v-model="user.voip_extension" />
                    </b-form-group>
                    <b-form-group label="VOIP Password" :invalid-feedback="validationInvalidFeedback(errors.errors, 'voip_password')">
                      <b-form-input :disabled="isSaving" type="password" :state="validationState(errors.errors, 'voip_password')" v-model="user.voip_password" />
                    </b-form-group>
                    <b-form-group label="VOIP IP" :invalid-feedback="validationInvalidFeedback(errors.errors, 'voip_ip')">
                      <b-form-input :disabled="isSaving" type="text" :state="validationState(errors.errors, 'voip_ip')" v-model="user.voip_ip" />
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-card>
            </b-col>
          </b-row>
          <b-row class="mb-4">
            <b-col cols="12">
              <b-card>
                <b-row>
                  <b-col class="d-flex flex-row" cols="12" @click.prevent="toggleCollapse('postcode_prefixes')" role="button">
                    <b-icon class="align-self-center cursor-pointer text-muted" font-scale="1.5" :icon="collapsed['postcode_prefixes'] ? 'chevron-right' : 'chevron-down'"></b-icon>
                    <div class="d-flex flex-column flex-fill ml-4">
                      <p class="h4 font-weight-bold mb-0">Postcode Prefixes</p>
                    </div>
                  </b-col>
                </b-row>
                <b-row v-if="!collapsed['postcode_prefixes']">
                  <b-col cols="12">
                    <hr>
                  </b-col>
                </b-row>
                <b-row v-if="!collapsed['postcode_prefixes']">
                  <b-col cols="12" v-if="!user.user_postcodes.length" class="p-4">
                    <b-row>
                      <b-col class="text-center" cols="12">
                        <b-card class="py-3 text-center">
                          <p class="mb-4"><fa-icon class="display-1 text-dark" icon="times"></fa-icon></p>
                          <p class="font-weight-normal h5 mb-0 text-dark">There are no postcode prefixes assigned to this user.</p>
                        </b-card>
                      </b-col>
                    </b-row>
                  </b-col>
                  <b-col cols="12" v-if="user.user_postcodes.length" class="p-4">
                    <b-row v-for="(userPostcode, userPostcodeIndex) in user.user_postcodes" :key="`userPostcode${userPostcodeIndex}`">
                      <b-col cols="11">
                        <b-form-input class="mb-2" placeholder="Postcode Prefix" v-model="userPostcode.prefix"></b-form-input>
                      </b-col>
                      <b-col cols="1">
                        <b-button variant="danger" @click="deleteUserPostcode(userPostcodeIndex)"><fa-icon icon="times" /></b-button>
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>
                <b-row v-if="!collapsed['postcode_prefixes']">
                  <b-col cols="12">
                    <b-button variant="primary" block @click="addUserPostcode">Add Postcode Prefix</b-button>
                  </b-col>
                </b-row>
              </b-card>
            </b-col>
          </b-row>
          <b-row class="mb-4">
            <b-col cols="6" md="3" offset-md="9">
              <b-button block type="submit" variant="secondary"><b-spinner small v-if="isSaving"></b-spinner><span v-if="!isSaving">Save</span></b-button>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12">
              <!-- <SelectPermissions :permissions="user.permissions" :roles="user.roles"></SelectPermissions> -->
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-form>
  </b-container>
</template>

<script>
import validation from '../../mixins/validation'
import { mapActions, mapGetters } from 'vuex'
import TeamSearch from '../../components/TeamSearch'
import Quill from '../../components/Quill'

export default {
  mixins: [validation],
  components: { TeamSearch, Quill },
  created () {
    if (this.$route.params.id) {
      this.fetch(this.$route.params.id)
    }

    this.fetchManyRoles('','').then(response => {
      this.roles = response.data.data;
      this.roles.forEach(role => {
        if(role.name == 'Installer') this.installerRoleId = role.id
      })
    });
    this.fetchManyShowrooms('','').then(response => {
      this.showrooms = response.data.data;
    });
    this.getUniqueUsers().then(response => {
      this.clockifyUsers = response.data
    })
  },
  computed: {
    ...mapGetters('users', ['errors', 'isLoading', 'isSaving', 'single']),
  },
  data () {
    return {
      collapsed: {
        voip_settings: !this.$route.params.id,
        postcode_prefixes: false,
      },

      rmGmailPassword: false,
      gmailPassword: null,

      roleId: null,
      installerRoleId: null,
      clockifyUsers: [],

      user: {
        name: null,
        email: null,
        voip_extension: null,
        voip_password: null,
        voip_ip: null,
        password: null,
        signature_content: null,
        signature_delta_content: null,
        verified: false,
        showroom_id: null,
        email_confirmation: null,
        password_confirmation: null,
        clockify_id: null,
        user_postcodes: [],
        has_gmail_password: false
      },
      roles: [],
      showrooms: [],
    }
  },
  methods: {
    ...mapActions('users', ['fetch', 'save']),
    ...mapActions('roles', {
      fetchManyRoles: 'fetchMany'
    }),
    ...mapActions('showrooms', {
      fetchManyShowrooms: 'fetchMany'
    }),
    ...mapActions('clockify-timesheets', ['getUniqueUsers']),
    submit () {
      let data = {
        id: this.$route.params.id,
        name: this.user.name,
        clockify_id: this.user.clockify_id,
        email: this.user.email,
        verified: this.user.verified,
        signature_content: this.user.signature_content,
        signature_delta_content: this.user.signature_delta_content,
        voip_extension: this.user.voip_extension,
        voip_password: this.user.voip_password,
        voip_ip: this.user.voip_ip,
        password: this.user.password,
        password_confirmation: this.user.password_confirmation,
        gmail_password: this.gmailPassword,
        rm_google_password: this.rmGmailPassword,
        role_id: this.roleId,
        showroom_id: this.user.showroom_id,
        user_postcodes: this.user.user_postcodes
      }

      this.save(data).then(response => {
        if([ 200, 201 ].includes(response.status)) this.$router.push({
          name: 'dash.users.list',
          params: {
            notifications: [{
              variant: 'success',
              title: `User #${response.data.id}`,
              subtitle: response.data.name,
              text: `The user has been ${this.$route.params.id ? 'updated' : 'created'} successfully.`,
              timeout: 5000
            }]
          }
        })
      })
    },

    toggleCollapse (name) {
      this.collapsed[name] = !this.collapsed[name]
    },

    addUserPostcode() {
      this.user.user_postcodes.push({
        id: null,
        prefix: null,
      })
    },

    deleteUserPostcode(ind) {
      this.user.user_postcodes.splice(ind, 1)
    }
  },
  watch: {
    single (newUser) {
      if(this.single.roles && this.single.roles.length) {
        this.roleId = this.single.roles[0].id;
      }
      if(this.single.team_id == null) {
        this.single.team = { id: null }
      }
      this.user = this.single
      this.user.verified = this.single.email_verified_at !== null
    }
  }
}
</script>

<style>

</style>
