<template>
  <router-view />
</template>

<script>
import userModule from '../../store/users'
import securityModule from '../../store/security'

export default {
  beforeCreate () {
    this.$store.registerModule('security', securityModule)
    this.$store.registerModule('users', userModule)
  },
  destroyed () {
    this.$store.unregisterModule('security')
    this.$store.unregisterModule('users')
  }
}
</script>
