import {
  CLOCKIFY_TIMESHEETS_FETCH_MANY_REQUEST,
  CLOCKIFY_TIMESHEETS_FETCH_MANY_SUCCESS,
  CLOCKIFY_TIMESHEETS_FETCH_MANY_FAILURE,
  EMPTY_CLOCKIFY_TIMESHEETS,
  CLEAR_ERRORS
} from './types'

export default {
  fetchMany ({ commit }, { query = '', page = 1, extraParams }) {
    commit(CLOCKIFY_TIMESHEETS_FETCH_MANY_REQUEST)
    return window.axios.get('/clockify/timesheets', { params: { query, page, ...extraParams } }).then(response => {
      commit(CLOCKIFY_TIMESHEETS_FETCH_MANY_SUCCESS, response)
      return response
    }).catch(error => {
      commit(CLOCKIFY_TIMESHEETS_FETCH_MANY_FAILURE, error)
      throw error
    })
  },
  getUniqueUsers({ commit }) {
    commit(CLOCKIFY_TIMESHEETS_FETCH_MANY_REQUEST)
    return window.axios.get('/clockify/users').then(response => {
      commit(CLOCKIFY_TIMESHEETS_FETCH_MANY_SUCCESS, response)
      return response
    }).catch(error => {
      commit(CLOCKIFY_TIMESHEETS_FETCH_MANY_FAILURE, error)
      throw error
    })
  },
  empty({ commit }) {
    commit(EMPTY_CLOCKIFY_TIMESHEETS);
  },
  clearErrors({ commit }) {
    commit(CLEAR_ERRORS)
  }
}
