export const VANS_FETCH_MANY_REQUEST = 'VANS_FETCH_MANY_REQUEST'
export const VANS_FETCH_MANY_SUCCESS = 'VANS_FETCH_MANY_SUCCESS'
export const VANS_FETCH_MANY_FAILURE = 'VANS_FETCH_MANY_FAILURE'

export const VANS_SET_TEAM_REQUEST = 'VANS_SET_TEAM_REQUEST'
export const VANS_SET_TEAM_SUCCESS = 'VANS_SET_TEAM_SUCCESS'
export const VANS_SET_TEAM_FAILURE = 'VANS_SET_TEAM_FAILURE'

export const VANS_SAVE_REQUEST = 'VANS_SAVE_REQUEST'
export const VANS_SAVE_SUCCESS = 'VANS_SAVE_SUCCESS'
export const VANS_SAVE_FAILURE = 'VANS_SAVE_FAILURE'

export const VANS_FETCH_REQUEST = 'VANS_FETCH_REQUEST'
export const VANS_FETCH_SUCCESS = 'VANS_FETCH_SUCCESS'
export const VANS_FETCH_FAILURE = 'VANS_FETCH_FAILURE'

export const EMPTY_VANS = 'EMPTY_VANS'