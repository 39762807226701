<template>
  <b-container fluid>
    <b-form @submit.prevent="submit">
      <b-row class="mb-3">
        <b-col cols="12">
          <h1 class="font-weight-bold text-primary">Update Van Phone</h1>
        </b-col>
      </b-row>
      <b-row v-if="isLoading">
        <b-col cols="12">
          <p class="text-center"><b-spinner variant="secondary"></b-spinner></p>
        </b-col>
      </b-row>
      <b-row v-if="!isLoading">
        <b-col cols="12">
          <b-row>
            <b-col cols="12">
              <b-form-group label="Vehicle Registration" disabled :invalid-feedback="validationInvalidFeedback(errors.errors, 'Van Registration')">
                <b-form-input :disabled="isSaving" :state="validationState(errors.errors, 'vehicle_registration')" v-model="van.vehicle_registration" />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12">
              <b-form-group label="Phone" :invalid-feedback="validationInvalidFeedback(errors.errors, 'phone')">
                <b-form-input :disabled="isSaving" type="text" :state="validationState(errors.errors, 'phone')" v-model="van.phone" />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row class="mb-4">
            <b-col cols="6" md="3" offset-md="9">
              <b-button block type="submit" variant="secondary"><b-spinner small v-if="isSaving"></b-spinner><span v-if="!isSaving">Save</span></b-button>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-form>
  </b-container>
</template>

<script>
import validation from '../../mixins/validation'
import { mapActions, mapGetters } from 'vuex'
import TeamSearch from '../../components/TeamSearch'

export default {
  mixins: [validation],
  components: { TeamSearch },
  created () {
    if (this.$route.params.id) {
        console.log(this.$route.params.id)
      this.fetch(this.$route.params.id)
        console.log(this.$route.params.id)
    }
  },
  computed: {
    ...mapGetters('vans', ['errors', 'isLoading', 'isSaving', 'single']),
  },
  data () {
    return {
      van: {
          id: null,
          team_id: null,
          phone: null,
          lat: null,
          lon: null,
          vehicle_registration: null,
          ignition: null,
          speed: null,
          speed_measure_text: null,
          direction: null,
          street: null,
          town: null,
          country: null,
          post_code: null,
      },
    }
  },
  methods: {
    ...mapActions('vans', ['fetch', 'save']),
    submit () {
      let data = {
        id: this.$route.params.id,
        phone: this.van.phone
      }

      this.save(data).then(response => {
        if([ 200, 201 ].includes(response.status)) this.$router.push({
          name: 'dash.vans.list',
          params: {
            notifications: [{
              variant: 'success',
              title: `Van #${response.data.id}`,
              subtitle: null,
              text: `The van has been updated successfully.`,
              timeout: 5000
            }]
          }
        })
      })
    },
  },
  watch: {
    single () {
      this.van = this.single
    }
  }
}
</script>

<style>

</style>
