import pagination from '../../utils/pagination'
import {
  REMINDERS_BATCH_DELETE_REQUEST, REMINDERS_BATCH_DELETE_SUCCESS, REMINDERS_BATCH_DELETE_FAILURE,
  REMINDERS_FETCH_MANY_REQUEST, REMINDERS_FETCH_MANY_SUCCESS, REMINDERS_FETCH_MANY_FAILURE,
  REMINDERS_FETCH_REQUEST, REMINDERS_FETCH_SUCCESS, REMINDERS_FETCH_FAILURE,
  REMINDERS_SAVE_REQUEST, REMINDERS_SAVE_SUCCESS, REMINDERS_SAVE_FAILURE,
  EMPTY_REMINDERS,
  CLEAR_ERRORS
} from './types'

export default {
  [REMINDERS_BATCH_DELETE_REQUEST] (state) {
    state.deleting = true
  },
  [REMINDERS_BATCH_DELETE_SUCCESS] (state) {
    state.deleting = false
  },
  [REMINDERS_BATCH_DELETE_FAILURE] (state) {
    state.deleting = false
  },
  [REMINDERS_FETCH_MANY_REQUEST] (state) {
    state.data = []
    state.loading = true
  },
  [REMINDERS_FETCH_MANY_SUCCESS] (state, { data }) {
    state.data = data.data
    state.page = pagination(data)
    state.loading = false
  },
  [REMINDERS_FETCH_MANY_FAILURE] (state, { validationErrors }) {
    state.validationErrors = validationErrors || {}
    state.loading = false
  },
  [REMINDERS_FETCH_REQUEST] (state) {
    state.single = {}
    state.loading = true
  },
  [REMINDERS_FETCH_SUCCESS] (state, { data }) {
    state.single = data
    state.loading = false
  },
  [REMINDERS_FETCH_FAILURE] (state) {
    state.loading = false
  },
  [REMINDERS_SAVE_REQUEST] (state) {
    state.data = []
    state.saving = true
  },
  [REMINDERS_SAVE_SUCCESS] (state) {
    state.saving = false
  },
  [REMINDERS_SAVE_FAILURE] (state, { validationErrors }) {
    state.validationErrors = validationErrors || {}
    state.saving = false
  },
  [EMPTY_REMINDERS] (state) {
    state.data = []
    state.loading = false
    state.saving = false
  },
  [CLEAR_ERRORS] (state) {
    state.validationErrors = []
    state.loading = false
    state.saving = false
  },
}
