import {
  VANS_FETCH_MANY_REQUEST,
  VANS_FETCH_MANY_SUCCESS,
  VANS_FETCH_MANY_FAILURE,

  VANS_SET_TEAM_REQUEST,
  VANS_SET_TEAM_SUCCESS,
  VANS_SET_TEAM_FAILURE,

  VANS_SAVE_REQUEST,
  VANS_SAVE_SUCCESS,
  VANS_SAVE_FAILURE,

  VANS_FETCH_REQUEST,
  VANS_FETCH_SUCCESS,
  VANS_FETCH_FAILURE,

  EMPTY_VANS
} from './types'

export default {
  fetchMany ({ commit }) {
    commit(VANS_FETCH_MANY_REQUEST)
    return window.axios.get('/vans').then(response => {
      commit(VANS_FETCH_MANY_SUCCESS, response)
      return response
    }).catch(error => {
      commit(VANS_FETCH_MANY_FAILURE, error)
      throw error
    })
  },
  setTeam ({ commit }, { vanId = null, teamId = null }) {
    return window.axios.post('/vans/set-team', { vanId, teamId }).then(response => {
      return response
    }).catch(error => {
      throw error
    })
  },
  fetch ({ commit }, id) {
    commit(VANS_FETCH_REQUEST)
    console.log(id)
    return window.axios.get(`vans/${id}`).then(response => {
      commit(VANS_FETCH_SUCCESS, response)
      return response
    }).catch(error => {
      commit(VANS_FETCH_FAILURE, error)
      throw error
    })
  },
  save ({ commit }, van) {
    const path = van.id ? `/vans/${van.id}` : '/vans'
    const method = van.id ? 'put' : 'post'

    commit(VANS_SAVE_REQUEST)
    return window.axios[method](path, van).then(response => {
      commit(VANS_SAVE_SUCCESS, response)
      return response
    }).catch(error => {
      if (error.response.status === 422) {
        commit(VANS_SAVE_FAILURE, { validationErrors: error.response.data })
      } else {
        commit(VANS_SAVE_FAILURE, { error })
      }
      throw error
    })
  },
  empty({ commit }) {
    commit(EMPTY_VANS);
  },
}
