import {
  VANS_FETCH_MANY_REQUEST, VANS_FETCH_MANY_SUCCESS, VANS_FETCH_MANY_FAILURE,
  VANS_SET_TEAM_REQUEST, VANS_SET_TEAM_SUCCESS, VANS_SET_TEAM_FAILURE,

  VANS_SAVE_REQUEST, VANS_SAVE_SUCCESS, VANS_SAVE_FAILURE,

  VANS_FETCH_REQUEST, VANS_FETCH_SUCCESS, VANS_FETCH_FAILURE,

  EMPTY_VANS
} from './types'

export default {
  [VANS_FETCH_MANY_REQUEST] (state) {
    state.loading = true
  },
  [VANS_FETCH_MANY_SUCCESS] (state, { data }) {
    state.data = data
    state.loading = false
  },
  [VANS_FETCH_MANY_FAILURE] (state, { validationErrors }) {
    state.validationErrors = validationErrors || {}
    state.loading = false
  },
  [VANS_SET_TEAM_REQUEST] (state) {
    state.loading = true
  },
  [VANS_SET_TEAM_SUCCESS] (state, { data }) {
    state.loading = false
  },
  [VANS_SET_TEAM_FAILURE] (state, { validationErrors }) {
    state.validationErrors = validationErrors || {}
    state.loading = false
  },
  [VANS_FETCH_REQUEST] (state) {
    state.single = {}
    state.loading = true
  },
  [VANS_FETCH_SUCCESS] (state, { data }) {
    state.single = data
    state.loading = false
  },
  [VANS_FETCH_FAILURE] (state) {
    state.loading = false
  },
  [VANS_SAVE_REQUEST] (state) {
    state.data = []
    state.saving = true
  },
  [VANS_SAVE_SUCCESS] (state) {
    state.saving = false
  },
  [VANS_SAVE_FAILURE] (state, { validationErrors }) {
    state.validationErrors = validationErrors || {}
    state.saving = false
  },
  [EMPTY_VANS] (state) {
    state.data = []
    state.loading = false
    state.saving = false
  },
}
