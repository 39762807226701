import {
  SECURITY_ACTIVATE_REQUEST,
  SECURITY_ACTIVATE_SUCCESS,
  SECURITY_ACTIVATE_FAILURE,

  SECURITY_VERIFY_REQUEST,
  SECURITY_VERIFY_SUCCESS,
  SECURITY_VERIFY_FAILURE,

  SECURITY_DEACTIVATE_REQUEST,
  SECURITY_DEACTIVATE_SUCCESS,
  SECURITY_DEACTIVATE_FAILURE,

  SECURITY_RESET_PASSWORD_REQUEST,
  SECURITY_RESET_PASSWORD_SUCCESS,
  SECURITY_RESET_PASSWORD_FAILURE,
} from './types'

export default {
  activate ({ commit }) {
    commit(SECURITY_ACTIVATE_REQUEST)
    return window.axios.get('/security/activate').then(response => {
      commit(SECURITY_ACTIVATE_SUCCESS, response)
      return response
    }).catch(error => {
      commit(SECURITY_ACTIVATE_FAILURE, error)
      throw error
    })
  },
  verify({ commit }, code) {
    commit(SECURITY_VERIFY_REQUEST)
    return window.axios.post('/security/verify', { code }).then(response => {
      commit(SECURITY_VERIFY_SUCCESS, response)
      return response
    }).catch(error => {
      commit(SECURITY_VERIFY_FAILURE, error)
      throw error
    })
  },
  deactivate({ commit }) {
    commit(SECURITY_DEACTIVATE_REQUEST)
    return window.axios.post('/security/deactivate').then(response => {
      commit(SECURITY_DEACTIVATE_SUCCESS, response)
      return response
    }).catch(error => {
      commit(SECURITY_DEACTIVATE_FAILURE, error)
      throw error
    })
  },
  resetPassword({ commit }, payload) {
    commit(SECURITY_RESET_PASSWORD_REQUEST)
    return window.axios.post('/security/reset-password', payload).then(response => {
      commit(SECURITY_RESET_PASSWORD_SUCCESS, response)
      return response
    }).catch(error => {
      commit(SECURITY_RESET_PASSWORD_FAILURE, { validationErrors: error.response.data })
      throw error
    })
  }
}
