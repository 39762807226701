export const SECURITY_ACTIVATE_REQUEST = 'SECURITY_ACTIVATE_REQUEST'
export const SECURITY_ACTIVATE_SUCCESS = 'SECURITY_ACTIVATE_SUCCESS'
export const SECURITY_ACTIVATE_FAILURE = 'SECURITY_ACTIVATE_FAILURE'

export const SECURITY_VERIFY_REQUEST = 'SECURITY_VERIFY_REQUEST'
export const SECURITY_VERIFY_SUCCESS = 'SECURITY_VERIFY_SUCCESS'
export const SECURITY_VERIFY_FAILURE = 'SECURITY_VERIFY_FAILURE'

export const SECURITY_DEACTIVATE_REQUEST = 'SECURITY_DEACTIVATE_REQUEST'
export const SECURITY_DEACTIVATE_SUCCESS = 'SECURITY_DEACTIVATE_SUCCESS'
export const SECURITY_DEACTIVATE_FAILURE = 'SECURITY_DEACTIVATE_FAILURE'

export const SECURITY_RESET_PASSWORD_REQUEST = 'SECURITY_RESET_PASSWORD_REQUEST'
export const SECURITY_RESET_PASSWORD_SUCCESS = 'SECURITY_RESET_PASSWORD_SUCCESS'
export const SECURITY_RESET_PASSWORD_FAILURE = 'SECURITY_RESET_PASSWORD_FAILURE'