import pagination from '../../utils/pagination'
import {
  TIMESHEETS_FETCH_MANY_REQUEST, TIMESHEETS_FETCH_MANY_SUCCESS, TIMESHEETS_FETCH_MANY_FAILURE,
  TIMESHEETS_SAVE_REQUEST, TIMESHEETS_SAVE_SUCCESS, TIMESHEETS_SAVE_FAILURE,

  EMPTY_TIMESHEETS,
  CLEAR_ERRORS
} from './types'

export default {
  [TIMESHEETS_FETCH_MANY_REQUEST] (state) {
    state.data = []
    state.loading = true
  },
  [TIMESHEETS_FETCH_MANY_SUCCESS] (state, { data }) {
    state.data = data.data
    state.page = pagination(data)
    state.loading = false
  },
  [TIMESHEETS_FETCH_MANY_FAILURE] (state, { validationErrors }) {
    state.validationErrors = validationErrors || {}
    state.loading = false
  },
  [TIMESHEETS_SAVE_REQUEST] (state) {
    state.data = []
    state.saving = true
  },
  [TIMESHEETS_SAVE_SUCCESS] (state) {
    state.saving = false
  },
  [TIMESHEETS_SAVE_FAILURE] (state, { validationErrors }) {
    state.validationErrors = validationErrors || {}
    state.saving = false
  },
  [EMPTY_TIMESHEETS] (state) {
    state.data = []
    state.loading = false
    state.saving = false
  },
  [CLEAR_ERRORS] (state) {
    state.validationErrors = []
    state.loading = false
    state.saving = false
  },
}
