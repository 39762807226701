<template>
  <router-view />
</template>

<script>
import userModule from '../../store/users'
import roleModule from '../../store/roles'
import showroomModule from '../../store/showrooms'
import teamModule from '../../store/teams'
import clockifyTimesheetsModule from '../../store/clockify-timesheets'

export default {
  beforeCreate () {
    this.$store.registerModule('users', userModule)
    this.$store.registerModule('roles', roleModule)
    this.$store.registerModule('showrooms', showroomModule)
    this.$store.registerModule('teams', teamModule)
    this.$store.registerModule('clockify-timesheets', clockifyTimesheetsModule)
  },
  destroyed () {
    this.$store.unregisterModule('users')
    this.$store.unregisterModule('roles')
    this.$store.unregisterModule('showrooms')
    this.$store.unregisterModule('teams')
    this.$store.unregisterModule('clockify-timesheets')
  }
}
</script>
