import pagination from '../../utils/pagination'
import {
  SHOWROOMS_BATCH_DELETE_REQUEST, SHOWROOMS_BATCH_DELETE_SUCCESS, SHOWROOMS_BATCH_DELETE_FAILURE,
  SHOWROOMS_FETCH_MANY_REQUEST, SHOWROOMS_FETCH_MANY_SUCCESS, SHOWROOMS_FETCH_MANY_FAILURE,
  SHOWROOMS_FETCH_REQUEST, SHOWROOMS_FETCH_SUCCESS, SHOWROOMS_FETCH_FAILURE,
  SHOWROOMS_SAVE_REQUEST, SHOWROOMS_SAVE_SUCCESS, SHOWROOMS_SAVE_FAILURE,

  EMPTY_SHOWROOMS,
  CLEAR_ERRORS
} from './types'

export default {
  [SHOWROOMS_BATCH_DELETE_REQUEST] (state) {
    state.deleting = true
  },
  [SHOWROOMS_BATCH_DELETE_SUCCESS] (state) {
    state.deleting = false
  },
  [SHOWROOMS_BATCH_DELETE_FAILURE] (state) {
    state.deleting = false
  },
  [SHOWROOMS_FETCH_MANY_REQUEST] (state) {
    state.data = []
    state.loading = true
  },
  [SHOWROOMS_FETCH_MANY_SUCCESS] (state, { data }) {
    state.data = data.data
    state.page = pagination(data)
    state.loading = false
  },
  [SHOWROOMS_FETCH_MANY_FAILURE] (state, { validationErrors }) {
    state.validationErrors = validationErrors || {}
    state.loading = false
  },
  [SHOWROOMS_FETCH_REQUEST] (state) {
    state.single = {}
    state.loading = true
  },
  [SHOWROOMS_FETCH_SUCCESS] (state, { data }) {
    state.single = data
    state.loading = false
  },
  [SHOWROOMS_FETCH_FAILURE] (state) {
    state.loading = false
  },
  [SHOWROOMS_SAVE_REQUEST] (state) {
    state.data = []
    state.saving = true
  },
  [SHOWROOMS_SAVE_SUCCESS] (state) {
    state.saving = false
  },
  [SHOWROOMS_SAVE_FAILURE] (state, { validationErrors }) {
    state.validationErrors = validationErrors || {}
    state.saving = false
  },
  [EMPTY_SHOWROOMS] (state) {
    state.data = []
    state.loading = false
    state.saving = false
  },
  [CLEAR_ERRORS] (state) {
    state.validationErrors = []
    state.loading = false
    state.saving = false
  },
}
