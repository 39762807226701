export const SHOWROOMS_BATCH_DELETE_REQUEST = 'SHOWROOMS_BATCH_DELETE_REQUEST'
export const SHOWROOMS_BATCH_DELETE_SUCCESS = 'SHOWROOMS_BATCH_DELETE_SUCCESS'
export const SHOWROOMS_BATCH_DELETE_FAILURE = 'SHOWROOMS_BATCH_DELETE_FAILURE'

export const SHOWROOMS_FETCH_MANY_REQUEST = 'SHOWROOMS_FETCH_MANY_REQUEST'
export const SHOWROOMS_FETCH_MANY_SUCCESS = 'SHOWROOMS_FETCH_MANY_SUCCESS'
export const SHOWROOMS_FETCH_MANY_FAILURE = 'SHOWROOMS_FETCH_MANY_FAILURE'

export const SHOWROOMS_FETCH_REQUEST = 'SHOWROOMS_FETCH_REQUEST'
export const SHOWROOMS_FETCH_SUCCESS = 'SHOWROOMS_FETCH_SUCCESS'
export const SHOWROOMS_FETCH_FAILURE = 'SHOWROOMS_FETCH_FAILURE'

export const SHOWROOMS_SAVE_REQUEST = 'SHOWROOMS_SAVE_REQUEST'
export const SHOWROOMS_SAVE_SUCCESS = 'SHOWROOMS_SAVE_SUCCESS'
export const SHOWROOMS_SAVE_FAILURE = 'SHOWROOMS_SAVE_FAILURE'

export const EMPTY_SHOWROOMS = 'EMPTY_SHOWROOMS'
export const CLEAR_ERRORS = 'CLEAR_ERRORS'
