<template>
  <b-container fluid>
    <b-row class="mb-4">
      <b-col cols="10">
        <h1 class="font-weight-bold mb-1">Vans</h1>
      </b-col>
      <b-col cols="2">
        <b-button variant="primary" class="float-right" @click="toggleView()">
          <fa-icon :icon="showing == 'list' ? 'map-marked' : 'list'"></fa-icon>
        </b-button>
      </b-col>
    </b-row>
    <b-row v-show="showing == 'list'">
      <b-col>
        <b-list-group>
          <b-list-group-item :key="van.id" v-for="van in vansList">
            <div class="d-flex flex-fill p-3">
              <div class="flex-column flex-fill">
                <p class="h5 mb-0">
                  <router-link class="text-primary" :to="{name: 'dash.vans.upsert', params: { id: van.id }}">{{ van.vehicle_registration }}</router-link>
                </p>
              </div>
            </div>
          </b-list-group-item>
        </b-list-group>
      </b-col>
    </b-row>
    <b-row class="mb-3" v-show="showing == 'map'">
      <b-col cols="12">
          <GmapMap
            :center="{lat:54, lng:-2}"
            :zoom="7"
            :options="{
              streetViewControl: false,
              disableDefaultUi: true,
              fullscreenControl: false,
              mapTypeContro: false,
            }"
            map-type-id="terrain"
            style="width: 100%; height: calc(100vh - 11.5rem)"
          >
            <GmapInfoWindow
              :key="`info${index}`"
              :options="getInfoWindowOptions(index)"
              :opened="true"
              :position="{ lat:Number(van.lat), lng: Number(van.lon) }"
              v-for="(van, index) in data"/>
            <GmapMarker
              :key="`marker${index}`"
              v-for="(van, index) in data"
              :position="{ lat:Number(van.lat), lng: Number(van.lon) }"
            />
          </GmapMap>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-modal id="van-modal" centered size="lg" :title="displayVan ? displayVan.vehicle_registration : 'Van'" v-model="displayModal">
          <template #default>
            <GmapMap
              v-if="displayVan"
              :center="{ lat:Number(displayVan.lat), lng: Number(displayVan.lon) }"
              :zoom="15"
              map-type-id="terrain"
              style="width: 100%; height: 50vh"
            >
              <GmapMarker
                :position="{ lat:Number(displayVan.lat), lng: Number(displayVan.lon) }"
              />
            </GmapMap>
          </template>
          <template #modal-footer>
            <b-container v-if="displayVan">
              <b-row>
                <b-col cols="12">
                  <b-row>
                    <b-col cols="7" class="d-flex flex-row flex-fill align-items-center justify-content-start">
                      <h2 class="font-weight-bold mt-1">{{ displayVan.vehicle_registration }}</h2>
                    </b-col>
                    <b-col cols="5" class="d-flex flex-row align-items-center justify-content-end">
                      <p>{{ displayVan.street }} | {{ displayVan.town }} | {{ displayVan.post_code }}</p>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="12">
                  <p class="mb-0">{{ displayVan.ignition == 'Y' ? `${displayVan.speed} ${displayVan.speed_measure_text}` : 'Ignition Off' }}</p>
                </b-col>
              </b-row>
              <b-row class="mt-3" v-if="displayVan.team">
                <b-col cols="12">
                  <b-row>
                    <b-col cols="7" class="d-flex flex-row flex-fill align-items-center justify-content-start">
                      <h4 class="font-weight-bold mt-1">{{ displayVan.team.name }}</h4>
                    </b-col>
                    <b-col cols="5" class="d-flex flex-row align-items-center justify-content-end">
                      <p class="mb-0">{{ displayVan.team.mobile }}</p>
                    </b-col>
                  </b-row>
                  <b-row v-for="(user, userInd) in displayVan.team.users" :key="`user${userInd}`">
                    <b-col cols="12">
                      <p class="font-weight-bold mt-1">{{ user.name }}</p>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
            </b-container>
          </template>
        </b-modal>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import FriendlyDate from '../../components/FriendlyDate'
import currentUser from '../../mixins/currentUser'
import { mapActions, mapGetters } from 'vuex'

window.openVanModal = function(ind) {
  window.dispatchEvent(new CustomEvent('open-van-modal', { detail: ind }))
}

export default {
  mixins: [currentUser],
  data() {
    return {
      displayVanIndex: null,
      vanInterval: null,

      vansList: [],
      showing: 'map',
    }
  },
  created () {
    this.fetchMany().then(response => {
      this.vansList = response.data
    })

    window.addEventListener('open-van-modal', payload => {
      this.displayVanIndex = payload.detail
    })
    this.$root.$on('bv::modal::hide', (bvEvent, modalId) => {
      if(modalId == 'van-modal') this.displayVanIndex = null
    })
  },
  components: {
    FriendlyDate,
  },
  mounted() {
    this.vanInterval = setInterval(() => {
      this.fetchMany()
    }, 2500)
  },
  beforeDestroy() {
    clearInterval(this.vanInterval)
  },
  computed: {
    ...mapGetters('vans', ['data', 'isLoading']),
    displayModal() { return Boolean(this.displayVanIndex || (this.displayVanIndex === 0)) },
    displayVan() { return this.data[this.displayVanIndex] },
  },
  methods: {
    ...mapActions('vans', ['fetchMany']),

    getInfoWindowOptions(ind) {
      return {
        content: '<span onclick="window.openVanModal('+ind+')">' + this.data[ind].vehicle_registration + '</span>',
        pixelOffset: {
          width: 0,
          height: -35,
        }
      }
    },

    toggleView() {
      switch(this.showing) {
        case 'list': this.showing = 'map'; break;
        case 'map': this.showing = 'list'; break;
      }
    },
  }
}
</script>

<style lang="scss">

  #van-modal {
    .modal-content {
      background-color: #F4F4F6 !important;
      border: 3px solid $primary;
    }

    .modal-header {
      h3 {
        color: $primary;
      }
    }

    .modal-body {
      padding: 0!important;
    }

    .modal-header,
    .modal-footer {
      color: $secondary;
      background-color: $primary;
      border-radius: 0;
    }

    .modal-header .close {
      color: #fff;
      text-shadow: none;
      opacity: 1;
    }
  }

  .gm-style-iw {
    .gm-ui-hover-effect {
      display: none!important;
    }
  }
  .gm-style .gm-style-iw-c {
    background: #4bb5a2;
    color: #fff;
    padding: 10px;
    border-radius: 0;
    text-align: center;
    box-shadow: none;
    font-weight: bold;
    .gm-style-iw-d {
      overflow: hidden!important;
      padding: 0 10px 10px 0!important;
    }
  }
  .gm-style .gm-style-iw-t::after {
    background: #4bb5a2;
  }
</style>
