import {
  SECURITY_ACTIVATE_REQUEST, SECURITY_ACTIVATE_SUCCESS, SECURITY_ACTIVATE_FAILURE,

  SECURITY_VERIFY_REQUEST, SECURITY_VERIFY_SUCCESS, SECURITY_VERIFY_FAILURE,

  SECURITY_DEACTIVATE_REQUEST, SECURITY_DEACTIVATE_SUCCESS, SECURITY_DEACTIVATE_FAILURE,

  SECURITY_RESET_PASSWORD_REQUEST, SECURITY_RESET_PASSWORD_SUCCESS, SECURITY_RESET_PASSWORD_FAILURE,

} from './types'

export default {
  [SECURITY_ACTIVATE_REQUEST] (state) {
    state.loading = true
  },
  [SECURITY_ACTIVATE_SUCCESS] (state, { data }) {
    state.data = data
    state.loading = false
  },
  [SECURITY_ACTIVATE_FAILURE] (state, { validationErrors }) {
    state.validationErrors = validationErrors || {}
    state.loading = false
  },
  [SECURITY_VERIFY_REQUEST] (state) {
    state.loading = true
  },
  [SECURITY_VERIFY_SUCCESS] (state, { data }) {
    state.data = data
    state.loading = false
  },
  [SECURITY_VERIFY_FAILURE] (state, { validationErrors }) {
    state.validationErrors = validationErrors || {}
    state.loading = false
  },
  [SECURITY_DEACTIVATE_REQUEST] (state) {
    state.loading = true
  },
  [SECURITY_DEACTIVATE_SUCCESS] (state, { data }) {
    state.data = data
    state.loading = false
  },
  [SECURITY_DEACTIVATE_FAILURE] (state, { validationErrors }) {
    state.validationErrors = validationErrors || {}
    state.loading = false
  },
  [SECURITY_RESET_PASSWORD_REQUEST] (state) {
    state.validationErrors = {}
    state.saving = true
  },
  [SECURITY_RESET_PASSWORD_SUCCESS] (state, { data }) {
    state.saving = false
  },
  [SECURITY_RESET_PASSWORD_FAILURE] (state, { validationErrors }) {
    state.validationErrors = validationErrors || {}
    state.saving = false
  },
}
