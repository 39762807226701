import {
  SHOWROOMS_BATCH_DELETE_REQUEST,
  SHOWROOMS_BATCH_DELETE_SUCCESS,
  SHOWROOMS_BATCH_DELETE_FAILURE,
  SHOWROOMS_FETCH_MANY_REQUEST,
  SHOWROOMS_FETCH_MANY_SUCCESS,
  SHOWROOMS_FETCH_MANY_FAILURE,
  SHOWROOMS_FETCH_REQUEST,
  SHOWROOMS_FETCH_SUCCESS,
  SHOWROOMS_FETCH_FAILURE,
  SHOWROOMS_SAVE_REQUEST,
  SHOWROOMS_SAVE_SUCCESS,
  SHOWROOMS_SAVE_FAILURE,

  EMPTY_SHOWROOMS,
  CLEAR_ERRORS
} from './types'

export default {
  deleteMany ({ commit }, { ids = [] }) {
    commit(SHOWROOMS_BATCH_DELETE_REQUEST)
    return window.axios.post('/showrooms', { _method: 'DELETE', ids }).then(response => {
      commit(SHOWROOMS_BATCH_DELETE_SUCCESS, response)
      return response
    }).catch(error => {
      commit(SHOWROOMS_BATCH_DELETE_FAILURE, error)
      throw error
    })
  },
  fetchMany ({ commit }, { query = '', page = 1 }) {
    commit(SHOWROOMS_FETCH_MANY_REQUEST)
    return window.axios.get('/showrooms', { params: { query, page } }).then(response => {
      commit(SHOWROOMS_FETCH_MANY_SUCCESS, response)
      return response
    }).catch(error => {
      commit(SHOWROOMS_FETCH_MANY_FAILURE, error)
      throw error
    })
  },
  fetch ({ commit }, id) {
    commit(SHOWROOMS_FETCH_REQUEST)
    return window.axios.get(`/showrooms/${id}`).then(response => {
      commit(SHOWROOMS_FETCH_SUCCESS, response)
      return response
    }).catch(error => {
      commit(SHOWROOMS_FETCH_FAILURE, error)
      throw error
    })
  },
  save ({ commit }, showroom) {
    const path = showroom.id ? `/showrooms/${showroom.id}` : '/showrooms'
    const method = showroom.id ? 'put' : 'post'

    commit(SHOWROOMS_SAVE_REQUEST)
    return window.axios[method](path, showroom).then(response => {
      commit(SHOWROOMS_SAVE_SUCCESS, response)
      return response
    }).catch(error => {
      if (error.response.status === 422) {
        commit(SHOWROOMS_SAVE_FAILURE, { validationErrors: error.response.data })
      } else {
        commit(SHOWROOMS_SAVE_FAILURE, { error })
      }
      throw error
    })
  },
  empty({ commit }) {
    commit(EMPTY_SHOWROOMS);
  },
  clearErrors({ commit }) {
    commit(CLEAR_ERRORS)
  }
}
