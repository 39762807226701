<template>
  <b-container fluid>
    <b-form @submit.prevent="submit">
      <b-row class="mb-3">
        <b-col cols="12">
          <h1 class="font-weight-bold text-primary">Security</h1>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12">
          <b-row class="mb-4">
            <b-col cols="12">
              <b-card>
                <b-row>
                  <b-col cols="12">
                    <div>
                      <p class="h4 font-weight-bold mb-0">Reset Password</p>
                    </div>
                  </b-col>
                  <b-col cols="12">
                    <div>
                      <hr>
                    </div>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col cols="12">
                    <b-form-group label="Password" :invalid-feedback="validationInvalidFeedback(errors.errors, 'password')">
                      <b-form-input :disabled="isSaving" :state="validationState(errors.errors, 'password')" type="password" v-model="password" />
                    </b-form-group>
                  </b-col>
                  <b-col cols="12">
                    <b-form-group label="Password Confirmation" :invalid-feedback="validationInvalidFeedback(errors.errors, 'password_confirmation')">
                      <b-form-input :disabled="isSaving" :state="validationState(errors.errors, 'password_confirmation')" type="password" v-model="passwordConfirmation" />
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col cols="12" md="4" offset-md="8">
                    <b-button block class="align-self-end" @click="doResetPassword" variant="secondary">
                      <b-spinner small v-if="isSaving"></b-spinner><span v-if="!isSaving && passwordResetStatus != true">Save</span><span v-if="!isSaving && passwordResetStatus"><fa-icon icon="check"/>  Saved</span>
                    </b-button>
                  </b-col>
                </b-row>
              </b-card>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12">
              <b-card>
                <b-row>
                  <b-col cols="12">
                    <div>
                      <p class="h4 font-weight-bold mb-0">Two Factor Authentication</p>
                    </div>
                  </b-col>
                  <b-col cols="12">
                    <div>
                      <hr>
                    </div>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col v-if="!totp.required">
                    <b-card class="py-3 text-center">
                      <p class="mb-4"><fa-icon class="display-1 text-dark" icon="check"></fa-icon></p>
                      <p class="font-weight-normal h5 mb-0 text-dark">You have successfully setup two factor authentication.</p>
                      <b-row>
                        <b-col cols="6" offset="3">
                          <b-button block class="mt-3" variant="outline-danger" @click="deactivateTotp()"><b-spinner small v-if="isLoading"></b-spinner><span v-if="!isLoading">Deactivate</span></b-button>
                        </b-col>
                      </b-row>
                    </b-card>
                  </b-col>
                </b-row>
                <b-row v-if="totp.required">
                  <b-col v-if="!totp.generating">
                    <b-card class="py-3 text-center">
                      <p class="mb-4"><fa-icon class="display-1 text-dark" icon="times"></fa-icon></p>
                      <p class="font-weight-normal h5 mb-0 text-dark">You do not have two factor authentication setup.</p>
                      <b-row>
                        <b-col cols="6" offset="3">
                          <b-button block class="mt-3" variant="primary" @click="getOtpData()"><b-spinner small v-if="isLoading"></b-spinner><span v-if="!isLoading">Setup 2FA</span></b-button>
                        </b-col>
                      </b-row>
                    </b-card>
                  </b-col>
                  <b-col v-if="totp.generating">
                    <b-row>
                      <b-col cols="12" xl="6" class="d-flex align-items-center justify-content-center">
                        <b-card>
                          <img class="object-fit w-100" :src="totp.qrCode">
                          <a class="btn btn-primary btn-block mt-3" :href="totp.otpUri">Save Code</a>
                          <small class="text-muted">This button will open your authentication app for you.</small>
                        </b-card>
                      </b-col>
                      <b-col cols="12" xl="6">
                        <b-card class="my-3">
                          <p class="font-weight-normal h5 mb-0 text-dark">2FA Setup</p>
                          <small class="text-muted">Scan this QR code using an authentication app on your device. Alternatively for mobile users you can click the "Save Code" button which will open and save the QR code data for you.</small>
                          <hr>
                          <b-form-group label="Authentication Code">
                            <b-form-input v-model="totp.verifyCode"></b-form-input>
                          </b-form-group>
                          <b-button block class="mt-3" variant="secondary" @click="verifyTotpCode()"><b-spinner small v-if="isLoading"></b-spinner><span v-if="!isLoading">Authenticate</span></b-button>
                        </b-card>
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>
              </b-card>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-form>
  </b-container>
</template>

<script>
import validation from '../../mixins/validation'
import currentUser from '../../mixins/currentUser'
import { mapActions, mapGetters } from 'vuex'
import TeamSearch from '../../components/TeamSearch'

export default {
  mixins: [validation, currentUser],
  components: { TeamSearch },
  computed: {
    ...mapGetters('security', ['errors', 'isLoading', 'isSaving', 'single']),
  },
  data () {
    return {
      password: null,
      passwordConfirmation: null,
      passwordResetStatus: null,

      totp: {
        required: true,
        generating: false,
        totpString: null,
        otpUri: null,
        qrCode: null,

        verifyCode: null,
      },

    }
  },
  created() {
    this.totp.required = this.currentUser.totp_required
  },
  methods: {
    ...mapActions('security', ['activate', 'verify', 'deactivate', 'resetPassword']),
    
    doResetPassword() {
      if(this.password && this.passwordConfirmation) {
        this.resetPassword({ password: this.password, password_confirmation: this.passwordConfirmation }).then(response => {
          if(response.data) {
            this.passwordResetStatus = true
            this.password = null
            this.passwordConfirmation = null
            setTimeout(() => {
              this.passwordResetStatus = null
            }, 1000)
          }
        }).catch(err => { this.passwordResetStatus = false })
      }
    },

    getOtpData() {
      this.activate().then(response => {
        this.totp.qrCode = response.data.qr_code
        this.totp.otpUri = response.data.otp_uri

        this.totp.generating = true
      })
    },

    verifyTotpCode() {
      this.verify(this.totp.verifyCode).then(response => {
        if(response.data) {
          this.totp.generating = false
          this.totp.required = false
        }
      })
    },

    deactivateTotp() {
      this.deactivate().then(response => {
        if(response.data) {
          this.totp.required = true
          this.totp.generating = false
        }
      })
    }

  },
}
</script>

<style>

</style>
