export const TIMESHEETS_FETCH_MANY_REQUEST = 'TIMESHEETS_FETCH_MANY_REQUEST'
export const TIMESHEETS_FETCH_MANY_SUCCESS = 'TIMESHEETS_FETCH_MANY_SUCCESS'
export const TIMESHEETS_FETCH_MANY_FAILURE = 'TIMESHEETS_FETCH_MANY_FAILURE'

export const TIMESHEETS_SAVE_REQUEST = 'TIMESHEETS_SAVE_REQUEST'
export const TIMESHEETS_SAVE_SUCCESS = 'TIMESHEETS_SAVE_SUCCESS'
export const TIMESHEETS_SAVE_FAILURE = 'TIMESHEETS_SAVE_FAILURE'

export const EMPTY_TIMESHEETS = 'EMPTY_TIMESHEETS'
export const CLEAR_ERRORS = 'CLEAR_ERRORS'
