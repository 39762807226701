<template>
  <b-container fluid>

    <b-row class="negate-header-padding">
      <b-col cols="12" md="2" class="left-panel px-2 d-none d-md-block" :class="{ 'd-block': showTabs, 'd-none': !showTabs }" v-show="showTabs">
        <b-row class="mt-3">
          <b-col cols="12">
            <ul class="left-panel-nav mb-3 pl-0 flex-column">
              <li class="navbar-text pl-0 left-panel-nav-header">General</li>
              <li class="left-panel-nav-item">
                <a href="#" class="nav-link" :class="{ 'active': currentTab == 'General' }" @click="currentTab = 'General'">General</a>
              </li>
              <li class="navbar-text pl-0 left-panel-nav-header">Statistics</li>
              <li class="left-panel-nav-item">
                <a href="#" class="nav-link" :class="{ 'active': currentTab == 'General Statistics' }" @click="currentTab = 'General Statistics'">General Statistics</a>
                <a href="#" class="nav-link" :class="{ 'active': currentTab == 'Products' }" @click="currentTab = 'Products'">Products</a>
                <a href="#" class="nav-link" :class="{ 'active': currentTab == 'Correspondence' }" @click="currentTab = 'Correspondence'">Correspondence</a>
              </li>
            </ul>
          </b-col>
        </b-row>
      </b-col>
      <b-col cols="12" md="10" class="content-container d-md-block" :class="{ 'd-none': showTabs }">
        <b-row class="mt-3">
          <b-col cols="12" class="d-flex flex-row">
            <h1 class="font-weight-bold mb-0 text-primary flex-fill">Overview</h1>
            <b-button variant="link" class="p-0 d-md-none" @click="showTabs = !showTabs"><fa-icon icon="sliders-h"/></b-button>
          </b-col>
          <b-col cols="12" class="mt-3">
            <DateRangePicker :from="fromDate" :to="toDate" @input="input => { this.fromDate = input.date1; this.toDate = input.date2; this.doFetch() }"></DateRangePicker>
          </b-col>
          <b-col cols="12" class="mt-3">
          </b-col>
        </b-row>

        <b-row>
          <b-col cols="12">
            <hr>
          </b-col>
        </b-row>

        <b-row>
          <b-col cols="12" v-show="currentTab == 'General'">
            <b-row class="mt-3">
              <b-col cols="12">
                <b-card>
                  <b-row>
                    <b-col cols="12">
                      <h4 class="text-muted mb-3">Your Reminders</h4>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col>
                      <b-table-simple hover responsive>
                        <b-thead>
                          <b-tr>
                            <b-th class="p-3">Customer Name</b-th>
                            <b-th class="p-3">Reminder Note</b-th>
                            <b-th class="text-right p-3">Reminder For</b-th>
                          </b-tr>
                        </b-thead>
                        <b-tbody v-if="!isLoading && single.reminders.length">
                          <b-tr v-for="(reminder, reminderInd) in single.reminders" :key="`reminder${reminderInd}`">
                            <b-td class="p-3">{{ reminder.customer.first_name }} {{ reminder.customer.surname }}</b-td>
                            <b-td class="p-3">{{ reminder.note }}</b-td>
                            <b-td class="text-right p-3">{{ moment(reminder.date).format('DD/MM/YYYY') }} @ {{ moment(reminder.time, 'HH:mm:ss').format('HH:mm') }}</b-td>
                          </b-tr>
                        </b-tbody>
                        <b-tbody v-if="!isLoading && !single.reminders.length">
                          <b-tr>
                            <b-td colspan="3" class="p-3 text-center">There are no reminders.</b-td>
                          </b-tr>
                        </b-tbody>
                        <b-tbody>
                          <b-tr v-if="isLoading">
                            <b-td colspan="3">
                              <div class="d-flex justify-content-center">
                                <b-spinner variant="secondary"/>
                              </div>
                            </b-td>
                          </b-tr>
                        </b-tbody>
                      </b-table-simple>
                    </b-col>
                  </b-row>
                </b-card>
              </b-col>
            </b-row>

            <b-row class="mt-3">
              <b-col cols="12">
                <b-card>
                  <b-row>
                    <b-col cols="12">
                      <h4 class="text-muted mb-3">Your Scheduled Calls</h4>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col>
                      <b-table-simple hover responsive>
                        <b-thead>
                          <b-tr>
                            <b-th class="p-3">Customer Name</b-th>
                            <b-th class="p-3">Call Notes</b-th>
                            <b-th class="p-3">Scheduled For</b-th>
                            <b-th class="text-right p-3">Duration</b-th>
                          </b-tr>
                        </b-thead>
                        <b-tbody v-if="!isLoading && single.scheduledCalls.length">
                          <b-tr v-for="(call, callInd) in single.scheduledCalls" :key="`call${callInd}`">
                            <b-td class="p-3">{{ call.name }}</b-td>
                            <b-td class="p-3">{{ call.note }}</b-td>
                            <b-td class="p-3">{{ moment(call.scheduled_date).format('DD/MM/YYYY') }} @ {{ moment(call.scheduled_time, 'HH:mm:ss').format('HH:mm') }}</b-td>
                            <b-td class="text-right p-3">{{ call.call_duration }} Minutes(s)</b-td>
                          </b-tr>
                        </b-tbody>
                        <b-tbody v-if="!isLoading && !single.scheduledCalls.length">
                          <b-tr>
                            <b-td colspan="4" class="p-3 text-center">There are no scheduled calls.</b-td>
                          </b-tr>
                        </b-tbody>
                        <b-tbody>
                          <b-tr v-if="isLoading">
                            <b-td colspan="4">
                              <div class="d-flex justify-content-center">
                                <b-spinner variant="secondary"/>
                              </div>
                            </b-td>
                          </b-tr>
                        </b-tbody>
                      </b-table-simple>
                    </b-col>
                  </b-row>
                </b-card>
              </b-col>
            </b-row>

            <b-row class="my-3">
              <b-col cols="12">
                <b-card>
                  <b-row>
                    <b-col cols="12">
                      <h4 class="text-muted">Correspondence</h4>
                      <hr>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col v-if="!isLoading && single.emails.length">
                      <b-row v-for="(message, messageInd) in single.emails" :key="messageInd">
                        <b-col cols="12">
                            <b-card class="mb-3">
                              <b-row>
                                  <b-col cols="12" md="7" class="d-flex flex-row align-items-center">
                                      <div class="mt-3 ml-3 mb-0 mb-md-3">
                                          <h5>{{ message.subject }}</h5>
                                          <h6>From: <small>{{ message.from_email }}</small></h6>
                                          <h6>To: <small>{{ message.to_email }}</small></h6>
                                      </div>
                                  </b-col>
                                  <b-col cols="12" md="5" class="d-flex flex-column flex-fill align-items-start justify-content-start align-items-md-end w-100 max-width">
                                      <div class="ml-3 mr-3 mt-0 mt-md-3">
                                          <small class="text-right"><span class="text-muted">{{ moment(message.date).format('DD/MM/YYYY @ HH:mm') }}</span></small>
                                      </div>
                                  </b-col>
                              </b-row>
                              <b-row>
                                  <b-col cols="12">
                                      <div class="m-3"><hr></div>
                                  </b-col>
                              </b-row>
                              <b-row>
                                  <b-col cols="12">
                                      <div class="m-3">
                                          <iframe :ref="`iframe${message.id}`" v-if="!isLoading" :srcdoc="message.body" frameborder="0" class="w-100"></iframe>
                                      </div>
                                  </b-col>
                              </b-row>
                              <b-row v-if="message.attachments && message.attachments.length">
                                  <b-col cols="12">
                                      <div class="mb-3 ml-3 mr-3">
                                          <p>Attachments:</p>
                                          <div class="d-flex flex-row flex-fill flex-wrap">
                                              <a target="_blank" :href="`/attachment/${attachment.id}`" class="btn btn-outline-secondary mr-2 m2" v-for="(attachment, attachmentInd) in message.attachments" :key="attachmentInd"><fa-icon icon="download" /> {{ attachment.name }}</a>
                                          </div>
                                      </div>
                                  </b-col>
                              </b-row>
                            </b-card>
                        </b-col>
                      </b-row>
                    </b-col>
                    <b-col v-if="isLoading" cols="12" class="d-flex justify-content-center">
                      <b-spinner variant="secondary"></b-spinner>
                    </b-col>
                    <b-col v-if="!isLoading && !single.emails.length" cols="12">
                      <b-card class="py-3 text-center">
                        <p class="mb-4"><fa-icon class="display-1 text-dark" icon="times"></fa-icon></p>
                        <p class="font-weight-normal h5 mb-0 text-dark">There is no correspondence.</p>
                      </b-card>
                    </b-col>
                  </b-row>
                </b-card>
              </b-col>
            </b-row>
          </b-col>

          <b-col cols="12" v-show="currentTab == 'General Statistics'">
            <b-row class="my-3">
              <b-col cols="12">
                <b-card>
                  <b-card-body class="p-1">
                    <h4 class="text-muted mb-4">Leaderboard</h4>
                    <b-table-simple responsive hover>
                      <b-thead>
                        <b-tr>
                          <b-th class="p-3">Name</b-th>
                          <b-th class="p-3">Leads</b-th>
                          <b-th class="p-3">Sales</b-th>
                          <b-th class="p-3">Conversion Rate</b-th>
                          <b-th class="p-3">AOV</b-th>
                          <b-th class="p-3">Revenue</b-th>
                          <b-th class="text-right p-3">Annual Conversion Rate</b-th>
                        </b-tr>
                      </b-thead>
                      <b-tbody v-if="!isLoading && single.leaderboard.length">
                        <b-tr v-for="(user, userInd) in single.leaderboard" :key="`user${userInd}`">
                          <b-td class="p-3">{{ user.name }}</b-td>
                          <b-td class="p-3">{{ user.leads_count }}</b-td>
                          <b-td class="p-3">{{ user.orders_count }} <b-icon class="text-muted" v-if="userInd == 0" icon="trophy-fill"/></b-td>
                          <b-td class="p-3">{{ user.conversion_rate }}%</b-td>
                          <b-td class="p-3">{{ user.aov | currencyFormat }}</b-td>
                          <b-td class="p-3">{{ user.revenue | currencyFormat }}</b-td>
                          <b-td class="text-right p-3">{{ user.annual_conversion_rate }}%</b-td>
                        </b-tr>
                      </b-tbody>
                      <b-tbody v-if="!isLoading && !single.leaderboard.length">
                        <b-tr>
                          <b-td colspan="7" class="p-3 text-center">There are no sales.</b-td>
                        </b-tr>
                      </b-tbody>
                      <b-tbody>
                        <b-tr v-if="isLoading">
                          <b-td colspan="7">
                            <div class="d-flex justify-content-center">
                              <b-spinner variant="secondary"/>
                            </div>
                          </b-td>
                        </b-tr>
                      </b-tbody>
                    </b-table-simple>
                  </b-card-body>
                </b-card>
              </b-col>
            </b-row>
            <b-row class="mb-3">
              <b-col cols="12" md="6">
                <b-card class="shadow-sm rounded">
                  <b-card-body class="d-flex flex-column align-items-center justify-content-center">
                    <h5 class="text-muted"> Leads Today </h5>
                    <span class="display-4" v-if="!isLoading">{{ single.leadsToday }}</span>
                    <span
                        class="text-success"
                        v-if="!isLoading && isFinite(calculatePercentageDiff(single.leadsYesterday, single.leadsToday))"
                        :class="{ 'text-danger': (calculatePercentageDiff(single.leadsYesterday, single.leadsToday) < 0) }"
                      >{{ calculatePercentageDiff(single.leadsYesterday, single.leadsToday) }}% <fa-icon :icon=" (calculatePercentageDiff(single.leadsYesterday, single.leadsToday) < 0) ? 'caret-down' : 'caret-up' "/></span>
                    <span v-if="!isLoading && !isFinite(calculatePercentageDiff(single.leadsYesterday, single.leadsToday))" class="text-success">0%</span>
                    <b-spinner v-if="isLoading" variant="secondary" class="mt-1"></b-spinner>
                  </b-card-body>
                </b-card>
              </b-col>

              <b-col cols="12" md="6">
                <b-card class="shadow-sm rounded">
                  <b-card-body class="d-flex flex-column align-items-center justify-content-center">
                    <h5 class="text-muted"> Sales Today </h5>
                    <span class="display-4" v-if="!isLoading">{{ single.salesToday }}</span>
                    <span
                        class="text-success"
                        v-if="!isLoading && isFinite(calculatePercentageDiff(single.salesYesterday, single.salesToday))"
                        :class="{ 'text-danger': (calculatePercentageDiff(single.salesYesterday, single.salesToday) < 0) }"
                      >{{ calculatePercentageDiff(single.salesYesterday, single.salesToday) }}% <fa-icon :icon=" (calculatePercentageDiff(single.salesYesterday, single.salesToday) < 0) ? 'caret-down' : 'caret-up' "/></span>
                    <span v-if="!isLoading && !isFinite(calculatePercentageDiff(single.salesYesterday, single.salesToday))" class="text-success">0%</span>
                    <b-spinner v-if="isLoading" variant="secondary" class="mt-1"></b-spinner>
                  </b-card-body>
                </b-card>
              </b-col>
            </b-row>
      
            <b-row class="mb-3">
              <b-col cols="12" md="6">
                <b-card class="shadow-sm rounded">
                  <b-card-body class="d-flex flex-column align-items-center justify-content-center">
                    <h5 class="text-muted"> Leads This Week </h5>
                    <span class="display-4" v-if="!isLoading">{{ single.leadsThisWeek }}</span>
                    <span
                        class="text-success"
                        v-if="!isLoading && isFinite(calculatePercentageDiff(single.leadsLastWeek, single.leadsThisWeek))"
                        :class="{ 'text-danger': (calculatePercentageDiff(single.leadsLastWeek, single.leadsThisWeek) < 0) }"
                      >{{ calculatePercentageDiff(single.leadsLastWeek, single.leadsThisWeek) }}% <fa-icon :icon=" (calculatePercentageDiff(single.leadsLastWeek, single.leadsThisWeek) < 0) ? 'caret-down' : 'caret-up' "/></span>
                    <span v-if="!isLoading && !isFinite(calculatePercentageDiff(single.leadsLastWeek, single.leadsThisWeek))" class="text-success">0%</span>
                    <b-spinner v-if="isLoading" variant="secondary" class="mt-1"></b-spinner>
                  </b-card-body>
                </b-card>
              </b-col>

              <b-col cols="12" md="6">
                <b-card class="shadow-sm rounded">
                  <b-card-body class="d-flex flex-column align-items-center justify-content-center">
                    <h5 class="text-muted"> Sales This Week </h5>
                    <span class="display-4" v-if="!isLoading">{{ single.salesThisWeek }}</span>
                    <span
                        class="text-success"
                        v-if="!isLoading && isFinite(calculatePercentageDiff(single.salesLastWeek, single.salesThisWeek))"
                        :class="{ 'text-danger': (calculatePercentageDiff(single.salesLastWeek, single.salesThisWeek) < 0) }"
                      >{{ calculatePercentageDiff(single.salesLastWeek, single.salesThisWeek) }}% <fa-icon :icon=" (calculatePercentageDiff(single.salesLastWeek, single.salesThisWeek) < 0) ? 'caret-down' : 'caret-up' "/></span>
                    <span v-if="!isLoading && !isFinite(calculatePercentageDiff(single.salesLastWeek, single.salesThisWeek))" class="text-success">0%</span>
                    <b-spinner v-if="isLoading" variant="secondary" class="mt-1"></b-spinner>
                  </b-card-body>
                </b-card>
              </b-col>
            </b-row>
      
            <b-row class="mb-3">
              <b-col cols="12" md="6">
                <b-card class="shadow-sm rounded">
                  <b-card-body class="d-flex flex-column align-items-center justify-content-center">
                    <h5 class="text-muted"> Leads This Month </h5>
                    <span class="display-4" v-if="!isLoading">{{ single.leadsThisMonth }}</span>
                    <span
                        class="text-success"
                        v-if="!isLoading && isFinite(calculatePercentageDiff(single.leadsLastMonth, single.leadsThisMonth))"
                        :class="{ 'text-danger': (calculatePercentageDiff(single.leadsLastMonth, single.leadsThisMonth) < 0) }"
                      >{{ calculatePercentageDiff(single.leadsLastMonth, single.leadsThisMonth) }}% <fa-icon :icon=" (calculatePercentageDiff(single.leadsLastMonth, single.leadsThisMonth) < 0) ? 'caret-down' : 'caret-up' "/></span>
                    <span v-if="!isLoading && !isFinite(calculatePercentageDiff(single.leadsLastMonth, single.leadsThisMonth))" class="text-success">0%</span>
                    <b-spinner v-if="isLoading" variant="secondary" class="mt-1"></b-spinner>
                  </b-card-body>
                </b-card>
              </b-col>

              <b-col cols="12" md="6">
                <b-card class="shadow-sm rounded">
                  <b-card-body class="d-flex flex-column align-items-center justify-content-center">
                    <h5 class="text-muted"> Sales This Month </h5>
                    <span class="display-4" v-if="!isLoading">{{ single.salesThisMonth }}</span>
                    <span
                        class="text-success"
                        v-if="!isLoading && isFinite(calculatePercentageDiff(single.salesLastMonth, single.salesThisMonth))"
                        :class="{ 'text-danger': (calculatePercentageDiff(single.salesLastMonth, single.salesThisMonth) < 0) }"
                      >{{ calculatePercentageDiff(single.salesLastMonth, single.salesThisMonth) }}% <fa-icon :icon=" (calculatePercentageDiff(single.salesLastMonth, single.salesThisMonth) < 0) ? 'caret-down' : 'caret-up' "/></span>
                    <span v-if="!isLoading && !isFinite(calculatePercentageDiff(single.salesLastMonth, single.salesThisMonth))" class="text-success">0%</span>
                    <b-spinner v-if="isLoading" variant="secondary" class="mt-1"></b-spinner>
                  </b-card-body>
                </b-card>
              </b-col>
            </b-row>
      
            <b-row class="mb-3">
              <b-col cols="12" md="6">
                <b-card class="shadow-sm rounded">
                  <b-card-body class="d-flex flex-column align-items-center justify-content-center">
                    <h5 class="text-muted"> Leads This Year </h5>
                      <span class="display-4" v-if="!isLoading">{{ single.leadsThisYear }}</span>
                      <span
                        class="text-success"
                        v-if="!isLoading && isFinite(calculatePercentageDiff(single.leadsLastYear, single.leadsThisYear))"
                        :class="{ 'text-danger': (calculatePercentageDiff(single.leadsLastYear, single.leadsThisYear) < 0) }"
                      >{{ calculatePercentageDiff(single.leadsLastYear, single.leadsThisYear) }}% <fa-icon :icon=" (calculatePercentageDiff(single.leadsLastYear, single.leadsThisYear) < 0) ? 'caret-down' : 'caret-up' "/></span>
                    <span v-if="!isLoading && !isFinite(calculatePercentageDiff(single.leadsLastYear, single.leadsThisYear))" class="text-success">0%</span>
                    <b-spinner v-if="isLoading" variant="secondary" class="mt-1"></b-spinner>
                  </b-card-body>
                </b-card>
              </b-col>

              <b-col cols="12" md="6">
                <b-card class="shadow-sm rounded">
                  <b-card-body class="d-flex flex-column align-items-center justify-content-center">
                    <h5 class="text-muted"> Sales This Year </h5>
                    <span class="display-4" v-if="!isLoading" v-show="moment().format('YYYY') == '2021'">{{ single.salesThisYear + 470 }}</span>
                    <span class="display-4" v-if="!isLoading" v-show="moment().format('YYYY') != '2021'">{{ single.salesThisYear }}</span>
                      <span
                        class="text-success"
                        v-if="!isLoading && isFinite(calculatePercentageDiff(single.salesLastYear, single.salesThisYear))"
                        :class="{ 'text-danger': (calculatePercentageDiff(single.salesLastYear, single.salesThisYear) < 0) }"
                      >{{ calculatePercentageDiff(single.salesLastYear, single.salesThisYear) }}% <fa-icon :icon=" (calculatePercentageDiff(single.salesLastYear, single.salesThisYear) < 0) ? 'caret-down' : 'caret-up' "/></span>
                    <span v-if="!isLoading && !isFinite(calculatePercentageDiff(single.salesLastYear, single.salesThisYear))" class="text-success">0%</span>
                    <b-spinner v-if="isLoading" variant="secondary" class="mt-1"></b-spinner>
                  </b-card-body>
                </b-card>
              </b-col>
            </b-row>

            <b-row class="mt-3">
              <b-col md="4">
                <b-card class="shadow-sm rounded">
                  <b-card-body class="d-flex flex-column align-items-center justify-content-center">
                    <h5 class="text-muted w-100 mb-4">Leads</h5>
                    <canvas ref="leadsByUserChart" id="overview-leadsByUserChart" width="400" height="300"></canvas>
                    <b-spinner v-if="isLoading" variant="secondary" class="mt-1"></b-spinner>
                  </b-card-body>
                </b-card>
              </b-col>
              <b-col md="4">
                <b-card class="shadow-sm rounded">
                  <b-card-body class="d-flex flex-column align-items-center justify-content-center">
                    <h5 class="text-muted w-100 mb-4">Leads By Source</h5>
                    <canvas ref="leadsBySourceChart" id="overview-leadsBySourceChart" width="400" height="300"></canvas>
                    <b-spinner v-if="isLoading" variant="secondary" class="mt-1"></b-spinner>
                  </b-card-body>
                </b-card>
              </b-col>
              <b-col md="4">
                <b-card class="shadow-sm rounded">
                  <b-card-body class="d-flex flex-column align-items-center justify-content-center">
                    <h5 class="text-muted w-100 mb-4"> Finance Vs Cash </h5>
                    <canvas ref="orderPaymentTypesChart" id="overview-orderPaymentTypesChart" width="400" height="300"></canvas>
                    <b-spinner v-if="isLoading" variant="secondary" class="mt-1"></b-spinner>
                  </b-card-body>
                </b-card>
              </b-col>
            </b-row>
            
            <b-row class="my-3">
              <b-col cols="12">
                <b-card class="shadow-sm rounded">
                  <b-card-body class="d-flex flex-column align-items-center justify-content-center">
                    <h5 class="text-muted w-100 mb-4"> Leads vs Sales </h5>
                    <canvas ref="leadsAndSalesChart" id="overview-leadsAndSalesChart" width="400" height="300"></canvas>
                    <b-spinner v-if="isLoading" variant="secondary" class="mt-1"></b-spinner>
                  </b-card-body>
                </b-card>
              </b-col>
            </b-row>
            
            <b-row class="my-3">
              <b-col cols="12" md="6">
                <b-card class="shadow-sm rounded">
                  <b-card-body class="d-flex flex-column align-items-center justify-content-center">
                    <h5 class="text-muted w-100 mb-4"> Sales By Month </h5>
                    <canvas ref="salesByMonthChart" id="overview-salesByMonthChart" width="400" height="300"></canvas>
                    <b-spinner v-if="isLoading" variant="secondary" class="mt-1"></b-spinner>
                  </b-card-body>
                </b-card>
              </b-col>
              <b-col cols="12" md="6">
                <b-card class="shadow-sm rounded">
                  <b-card-body class="d-flex flex-column align-items-center justify-content-center">
                    <h5 class="text-muted w-100 mb-4"> Sales Over the Past 52 Weeks </h5>
                    <canvas ref="salesOver52WeeksChart" id="overview-salesOver52WeeksChart" width="400" height="300"></canvas>
                    <b-spinner v-if="isLoading" variant="secondary" class="mt-1"></b-spinner>
                  </b-card-body>
                </b-card>
              </b-col>
            </b-row>
            
            <b-row class="my-3">
              <b-col cols="12" md="6">
                <b-card class="shadow-sm rounded">
                  <b-card-body class="d-flex flex-column align-items-center justify-content-center">
                    <h5 class="text-muted w-100 mb-4"> Revenue By Month </h5>
                    <canvas ref="revenueByMonthChart" id="overview-revenueByMonthChart" width="400" height="300"></canvas>
                    <b-spinner v-if="isLoading" variant="secondary" class="mt-1"></b-spinner>
                  </b-card-body>
                </b-card>
              </b-col>
              <b-col cols="12" md="6">
                <b-card class="shadow-sm rounded">
                  <b-card-body class="d-flex flex-column align-items-center justify-content-center">
                    <h5 class="text-muted w-100 mb-4"> Revenue Over the Past 52 Weeks </h5>
                    <canvas ref="revenueOver52WeeksChart" id="overview-revenueOver52WeeksChart" width="400" height="300"></canvas>
                    <b-spinner v-if="isLoading" variant="secondary" class="mt-1"></b-spinner>
                  </b-card-body>
                </b-card>
              </b-col>
            </b-row>

            <b-row class="my-3">
              <b-col cols="12">
                <b-card class="shadow-sm rounded">
                  <b-card-body class="d-flex flex-column align-items-center justify-content-center">
                    <h5 class="text-muted w-100 mb-4"> Support </h5>
                    <canvas ref="supportChart" id="overview-supportChart" width="400" height="300"></canvas>
                    <b-spinner v-if="isLoading" variant="secondary" class="mt-1"></b-spinner>
                  </b-card-body>
                </b-card>
              </b-col>
            </b-row>

          </b-col>

          <b-col cols="12" v-show="currentTab == 'Products'">
            <b-row class="mt-3">
              <b-col>
                <b-card class="shadow-sm rounded">
                  <b-card-body class="d-flex flex-column align-items-center justify-content-center">
                    <h5 class="text-muted w-100 mb-4"> Best Sellers </h5>
                    <canvas ref="bestSellersChart" id="overview-bestSellersChart" width="400" height="300"></canvas>
                    <b-spinner v-if="isLoading" variant="secondary" class="mt-1"></b-spinner>
                  </b-card-body>
                </b-card>
              </b-col>
            </b-row>
            <b-row class="mt-3">
              <b-col cols="12">
                <b-card>
                  <b-row>
                    <b-col class="d-flex flex-row" cols="12" md="9" @click.prevent="toggleCollapse('best_sellers')" role="button">
                      <b-icon class="align-self-center cursor-pointer text-muted" font-scale="1.5" :icon="collapsed['best_sellers'] ? 'chevron-right' : 'chevron-down'"></b-icon>
                      <div class="d-flex flex-column flex-fill ml-4">
                        <p class="h4 text-muted mb-0">Best Sellers</p>
                      </div>
                    </b-col>
                  </b-row>
                  <b-row class="mt-4" v-if="!collapsed['best_sellers']">
                    <b-col cols="12">
                      <b-tabs class="w-100" v-if="!isLoading">
                        <b-tab title="This Month">
                          <b-table-simple>
                            <b-thead>
                              <b-tr>
                                <b-th>Product</b-th>
                                <b-th>Sales</b-th>
                              </b-tr>
                            </b-thead>
                            <b-tbody>
                              <b-tr v-for="product in single.bestSellersTableData.monthly" :key="`this-month-${product.product_line}`">
                                <b-th>{{ product.product_line }}</b-th>
                                <b-th>{{ product.sale_count }}</b-th>
                              </b-tr>
                            </b-tbody>
                          </b-table-simple>
                        </b-tab>
                        <b-tab title="This Year">
                          <b-table-simple>
                            <b-thead>
                              <b-tr>
                                <b-th>Product</b-th>
                                <b-th>Sales</b-th>
                              </b-tr>
                            </b-thead>
                            <b-tbody>
                              <b-tr v-for="product in single.bestSellersTableData.yearly" :key="`this-year-${product.product_line}`">
                                <b-th>{{ product.product_line }}</b-th>
                                <b-th>{{ product.sale_count }}</b-th>
                              </b-tr>
                            </b-tbody>
                          </b-table-simple>
                        </b-tab>
                        <b-tab title="All Time">
                          <b-table-simple>
                            <b-thead>
                              <b-tr>
                                <b-th>Product</b-th>
                                <b-th>Sales</b-th>
                              </b-tr>
                            </b-thead>
                            <b-tbody>
                              <b-tr v-for="product in single.bestSellersTableData.all_time" :key="`all-time-${product.product_line}`">
                                <b-th>{{ product.product_line }}</b-th>
                                <b-th>{{ product.sale_count }}</b-th>
                              </b-tr>
                            </b-tbody>
                          </b-table-simple>
                        </b-tab>
                      </b-tabs>
                    </b-col>
                  </b-row>
                </b-card>
              </b-col>
            </b-row>
            <b-row class="mt-3">
              <b-col cols="12">
                <b-card>
                  <b-row>
                    <b-col class="d-flex flex-row" cols="12" md="9" @click.prevent="toggleCollapse('time_in_storage')" role="button">
                      <b-icon class="align-self-center cursor-pointer text-muted" font-scale="1.5" :icon="collapsed['time_in_storage'] ? 'chevron-right' : 'chevron-down'"></b-icon>
                      <div class="d-flex flex-column flex-fill ml-4">
                        <p class="h4 text-muted mb-0">Average Time in Storage</p>
                      </div>
                    </b-col>
                  </b-row>
                  <b-row class="mt-4" v-if="!collapsed['time_in_storage']">
                    <b-col cols="12">
                      <b-table-simple hover>
                        <b-thead>
                          <b-tr>
                            <b-th class="p-3">Name</b-th>
                            <b-th class="text-right p-3">Time in Storage</b-th>
                          </b-tr>
                        </b-thead>
                        <b-tbody v-if="!isLoading && single.timeToSaleData.length">
                          <b-tr v-for="(product, productInd) in single.timeToSaleData" :key="`tts${productInd}`">
                            <b-td class="p-3">{{ product.name }}</b-td>
                            <b-td class="text-right p-3">{{ product.avg_time_to_sale_display }}</b-td>
                          </b-tr>
                        </b-tbody>
                        <b-tbody v-if="!isLoading && !single.timeToSaleData.length">
                          <b-tr>
                            <b-td colspan="2" class="p-3 text-center">There are no products.</b-td>
                          </b-tr>
                        </b-tbody>
                        <b-tbody>
                          <b-tr v-if="isLoading">
                            <b-td colspan="2">
                              <div class="d-flex justify-content-center">
                                <b-spinner variant="secondary"/>
                              </div>
                            </b-td>
                          </b-tr>
                        </b-tbody>
                      </b-table-simple>
                    </b-col>
                  </b-row>
                </b-card>
              </b-col>
            </b-row>
            <b-row class="my-3">
              <b-col cols="12">
                <b-card>
                  <b-card-body class="p-1">
                    <b-row class="mb-4">
                      <b-col cols="12">
                        <h4 class="text-muted mb-0">Stock Levels</h4>
                        <small class="text-muted">Only displaying products currently in stock or on order.</small>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col cols="12">
                        <b-table-simple hover responsive>
                          <b-thead>
                            <b-tr>
                              <b-th class="p-3">Name</b-th>
                              <b-th class="p-3">
                                <div class="d-flex flex-column text-center align-items-center">
                                  Warehouse Stock <small class="text-muted">Sold</small>
                                </div>
                              </b-th>
                              <b-th class="p-3">
                                <div class="d-flex flex-column text-center align-items-center">
                                  Warehouse Stock <small class="text-muted">Not Sold</small>
                                </div>
                              </b-th>
                              <b-th class="p-3">
                                <div class="d-flex flex-column text-center align-items-center">
                                  Incoming Stock <small class="text-muted">Sold</small>
                                </div>
                              </b-th>
                              <b-th class="p-3">
                                <div class="d-flex flex-column text-center align-items-center">
                                  Incoming Stock <small class="text-muted">Not Sold</small>
                                </div>
                              </b-th>
                            </b-tr>
                          </b-thead>
                          <b-tbody v-if="!isLoading && single.stockList.length">
                            <b-tr v-for="(product, productInd) in single.stockList" :key="`product${productInd}`">
                              <b-td class="p-3">
                                <router-link class="mr-1 mb-0" size="sm" variant="secondary" v-b-popover.hover.right="'View the stock for this product.'" :to="{name: 'dash.stock.table', params: { product_id: product.id }}">{{ product.name }}</router-link>
                              </b-td>
                              <b-td class="text-center p-3">{{ product.available_allocated_count }}</b-td>
                              <b-td class="text-center p-3">{{ product.available_unallocated_count }}</b-td>
                              <b-td class="text-center p-3">{{ product.allocated_incoming_count }}</b-td>
                              <b-td class="text-center p-3">{{ product.unallocated_incoming_count }}</b-td>
                            </b-tr>
                            <b-tr>
                              <b-th>Total</b-th>
                              <b-th class="text-center p-3">{{ single.totalAvailableAllocatedStock }}</b-th>
                              <b-th class="text-center p-3">{{ single.totalAvailableUnallocatedStock }}</b-th>
                              <b-th class="text-center p-3">{{ single.totalIncomingAllocatedStock }}</b-th>
                              <b-th class="text-center p-3">{{ single.totalIncomingUnallocatedStock }}</b-th>
                            </b-tr>
                          </b-tbody>
                          <b-tbody v-if="!isLoading && !single.stockList.length">
                            <b-tr>
                              <b-td colspan="5" class="p-3 text-center">There are no products in stock or on order.</b-td>
                            </b-tr>
                          </b-tbody>
                          <b-tbody>
                            <b-tr v-if="isLoading">
                              <b-td colspan="5">
                                <div class="d-flex justify-content-center">
                                  <b-spinner variant="secondary"/>
                                </div>
                              </b-td>
                            </b-tr>
                          </b-tbody>
                        </b-table-simple>
                      </b-col>
                    </b-row>
                  </b-card-body>
                </b-card>
              </b-col>
            </b-row>
          </b-col>

          <b-col cols="12" v-show="currentTab == 'Correspondence'">
            <b-row class="mt-3">
              <b-col md="6">
                <b-card class="shadow-sm rounded">
                  <b-card-body class="d-flex flex-column align-items-center justify-content-center">
                    <h5 class="text-muted w-100 mb-4"> Chats and Enquiries </h5>
                    <canvas ref="chatsChart" id="overview-chatsChart" width="400" height="300"></canvas>
                    <b-spinner v-if="isLoading" variant="secondary" class="mt-1"></b-spinner>
                  </b-card-body>
                </b-card>
              </b-col>
              <b-col md="6">
                <b-card class="shadow-sm rounded">
                  <b-card-body class="d-flex flex-column align-items-center justify-content-center">
                    <h5 class="text-muted w-100 mb-4"> Calls </h5>
                    <canvas ref="callsChart" id="overview-callsChart" width="400" height="300"></canvas>
                    <b-spinner v-if="isLoading" variant="secondary" class="mt-1"></b-spinner>
                  </b-card-body>
                </b-card>
              </b-col>
            </b-row>

            <b-row class="my-3">
              <b-col md="6">
                <b-card class="shadow-sm rounded">
                  <b-card-body class="d-flex flex-column align-items-center justify-content-center">
                    <h5 class="text-muted w-100 mb-4"> Missed Calls </h5>
                    <canvas ref="missedCallsChart" id="overview-missedCallsChart" width="400" height="300"></canvas>
                    <b-spinner v-if="isLoading" variant="secondary" class="mt-1"></b-spinner>
                  </b-card-body>
                </b-card>
              </b-col>
              <b-col md="6">
                <b-card class="shadow-sm rounded">
                  <b-card-body class="d-flex flex-column align-items-center justify-content-center">
                    <h5 class="text-muted w-100 mb-4"> Calls By Extension </h5>
                    <canvas ref="callsByExtensionChart" id="overview-callsByExtensionChart" width="400" height="300"></canvas>
                    <b-spinner v-if="isLoading" variant="secondary" class="mt-1"></b-spinner>
                  </b-card-body>
                </b-card>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </b-col>
    </b-row>

  </b-container>
</template>

<script>
import FriendlyDate from '../../components/FriendlyDate'
import DateRangePicker from '../../components/DateRangePicker'
import currentUser from '../../mixins/currentUser'
import momentMixin from '../../mixins/momentMixin'
import { mapActions, mapGetters } from 'vuex'
import Chart from 'chart.js/auto';

export default {
  mixins: [currentUser, momentMixin],
  data: () => ({
    fromDate: null,
    toDate: null,

    collapsed: {
      best_sellers: true,
      time_in_storage: true,
      correspondence: false,
    },

    currentTab: 'General',
    showTabs: false,

    leadsAndSalesChart: null,
    leadsAndSalesChartConf: {
      type: 'bar',
    },

    supportChart: null,
    supportChartConf: {
      type: 'bar',
    },

    bestSellersChart: null,
    bestSellersChartConf: {
      type: 'bar',
    },
    leadsByUserChart: null,
    leadsByUserChartConf: {
      type: 'pie',
    },
    leadsBySourceChart: null,
    leadsBySourceChartConf: {
      type: 'pie',
    },
    orderPaymentTypesChart: null,
    orderPaymentTypesChartConf: {
      type: 'pie',
    },
    chatsChart: null,
    chatsChartConf: {
      type: 'line',
    },
    callsChart: null,
    callsChartConf: {
      type: 'line',
    },
    salesByMonthChart: null,
    salesByMonthChartConf: {
      type: 'line',
    },
    salesOver52WeeksChart: null,
    salesOver52WeeksChartConf: {
      type: 'line',
    },
    revenueByMonthChart: null,
    revenueByMonthChartConf: {
      type: 'line',
    },
    revenueOver52WeeksChart: null,
    revenueOver52WeeksChartConf: {
      type: 'line',
    },
    missedCallsChart: null,
    missedCallsChartConf: {
      type: 'line',
    },
    callsByExtensionChart: null,
    callsByExtensionChartConf: {
      type: 'line',
    },
  }),
  created () {
    this.fromDate = this.moment().startOf('month');
    this.toDate = this.moment();
    this.doFetch()
  },
  components: {
    FriendlyDate,
    DateRangePicker
  },
  watch: {
    currentTab() {
      this.showTabs = false
    },


    single() {

      if(this.single.hasOwnProperty('leadsAndSalesChartData')) {
        if(this.leadsAndSalesChart) {
          this.leadsAndSalesChart.destroy()
        }
        this.leadsAndSalesChartConf = {
          type: 'bar',
          data: {
            labels: this.single.leadsAndSalesChartData.labels,
            datasets: [
              this.single.leadsAndSalesChartData.leads,
              this.single.leadsAndSalesChartData.sales,
            ]
          },        
        }
        this.leadsAndSalesChart = new Chart(
          this.$refs['leadsAndSalesChart'],
            {...this.leadsAndSalesChartConf});
      }

      if(this.single.hasOwnProperty('supportChartData')) {
        if(this.supportChart) {
          this.supportChart.destroy()
        }
        this.supportChartConf = {
          type: 'bar',
          data: {
            labels: this.single.supportChartData.labels,
            datasets: [
              this.single.supportChartData.tickets,
              this.single.supportChartData.repairs,
            ]
          },        
        }
        this.supportChart = new Chart(
          this.$refs['supportChart'],
            {...this.supportChartConf});
      }

      if(this.single.hasOwnProperty('leadsByUserChart')) {
        if(this.leadsByUserChart) {
          this.leadsByUserChart.destroy()
        }
        this.leadsByUserChartDataConf = {
          type: 'pie',
          data: {
            labels: this.single.leadsByUserChart.labels,
            datasets: [{
              label: 'Data',
              data: this.single.leadsByUserChart.datasets,
              backgroundColor: [
                '#81cbbe',
                '#1e4841',
                '#357f71',
                '#5dbcab',
                '#4bb5a2',
                '#3c9182',
                '#2d6d61',
                '#6fc4b5',
              ],
            }],
          },
        }
        this.leadsByUserChart = new Chart(
          this.$refs['leadsByUserChart'],
            {...this.leadsByUserChartDataConf});
      }

      if(this.single.hasOwnProperty('leadsBySourceChart')) {
        if(this.leadsBySourceChart) {
          this.leadsBySourceChart.destroy()
        }
        this.leadsBySourceChartDataConf = {
          type: 'pie',
          data: {
            labels: this.single.leadsBySourceChart.labels,
            datasets: [{
              label: 'Data',
              data: this.single.leadsBySourceChart.datasets,
              backgroundColor: [
                '#81cbbe',
                '#1e4841',
                '#357f71',
                '#5dbcab',
                '#4bb5a2',
                '#3c9182',
                '#2d6d61',
                '#6fc4b5',
              ],
            }],
          },
        }
        this.leadsBySourceChart = new Chart(
          this.$refs['leadsBySourceChart'],
            {...this.leadsBySourceChartDataConf});
      }

      if(this.single.hasOwnProperty('orderPaymentTypesChart')) {
        if(this.orderPaymentTypesChart) {
          this.orderPaymentTypesChart.destroy()
        }
        this.orderPaymentTypesChartDataConf = {
          type: 'pie',
          data: {
            labels: this.single.orderPaymentTypesChart.labels,
            datasets: [{
              label: 'Data',
              data: this.single.orderPaymentTypesChart.datasets,
              backgroundColor: [
                '#4bb5a2',
                '#3c9182',
                '#2d6d61',
                '#6fc4b5',
                '#81cbbe',
                '#1e4841',
                '#357f71',
                '#5dbcab',
              ],
            }],
          },
        }
        this.orderPaymentTypesChart = new Chart(
          this.$refs['orderPaymentTypesChart'],
            {...this.orderPaymentTypesChartDataConf});
      }

      if(this.single.hasOwnProperty('bestSellersChartData')) {
        if(this.bestSellersChart) {
          this.bestSellersChart.destroy()
        }
        this.bestSellersChartConf = {
          type: 'bar',
          options: {
            plugins: {
              legend: {
                display: false
              },
            },
            scales: {
              'x': {
                display: false,
              }
            }
          },
          data: {
            labels: this.single.bestSellersChartData.labels,
            datasets: [
              this.single.bestSellersChartData.products,
            ]
          },
        }
        this.bestSellersChart = new Chart(
          this.$refs['bestSellersChart'],
            {...this.bestSellersChartConf});
      }

      if(this.single.hasOwnProperty('chatsChartData')) {
        if(this.chatsChart) {
          this.chatsChart.destroy()
        }
        this.chatsChartConf = {
          type: 'line',
          options: {
            plugins: {
              legend: {
                display: false
              }
            }
          },
          data: {
            labels: this.single.chatsChartData.labels,
            datasets: this.single.chatsChartData.datasets
          }
        }
        this.chatsChart = new Chart(
          this.$refs['chatsChart'],
            {...this.chatsChartConf});
      }

      if(this.single.hasOwnProperty('callsChartData')) {
        if(this.callsChart) {
          this.callsChart.destroy()
        }
        this.callsChartConf = {
          type: 'line',
          options: {
            plugins: {
              legend: {
                display: false
              }
            }
          },
          data: {
            labels: this.single.callsChartData.labels,
            datasets: this.single.callsChartData.datasets
          }
        }
        this.callsChart = new Chart(
          this.$refs['callsChart'],
            {...this.callsChartConf});
      }

      if(this.single.hasOwnProperty('salesByMonthChartData')) {
        if(this.salesByMonthChart) {
          this.salesByMonthChart.destroy()
        }
        this.salesByMonthChartConf = {
          type: 'line',
          options: {
            plugins: {
              legend: {
                display: false
              }
            }
          },
          data: {
            labels: this.single.salesByMonthChartData.labels,
            datasets: this.single.salesByMonthChartData.datasets
          }
        }
        this.salesByMonthChart = new Chart(
          this.$refs['salesByMonthChart'],
            {...this.salesByMonthChartConf});
      }

      if(this.single.hasOwnProperty('salesOver52WeeksChartData')) {
        if(this.salesOver52WeeksChart) {
          this.salesOver52WeeksChart.destroy()
        }
        this.salesOver52WeeksChartConf = {
          type: 'line',
          options: {
            plugins: {
              legend: {
                display: false
              }
            }
          },
          data: {
            labels: this.single.salesOver52WeeksChartData.labels,
            datasets: this.single.salesOver52WeeksChartData.datasets
          }
        }
        this.salesOver52WeeksChart = new Chart(
          this.$refs['salesOver52WeeksChart'],
            {...this.salesOver52WeeksChartConf});
      }

      if(this.single.hasOwnProperty('revenueByMonthChartData')) {
        if(this.revenueByMonthChart) {
          this.revenueByMonthChart.destroy()
        }
        this.revenueByMonthChartConf = {
          type: 'line',
          options: {
            plugins: {
              legend: {
                display: false
              }
            }
          },
          data: {
            labels: this.single.revenueByMonthChartData.labels,
            datasets: this.single.revenueByMonthChartData.datasets
          }
        }
        this.revenueByMonthChart = new Chart(
          this.$refs['revenueByMonthChart'],
            {...this.revenueByMonthChartConf});
      }

      if(this.single.hasOwnProperty('revenueOver52WeeksChartData')) {
        if(this.revenueOver52WeeksChart) {
          this.revenueOver52WeeksChart.destroy()
        }
        this.revenueOver52WeeksChartConf = {
          type: 'line',
          options: {
            plugins: {
              legend: {
                display: false
              }
            }
          },
          data: {
            labels: this.single.revenueOver52WeeksChartData.labels,
            datasets: this.single.revenueOver52WeeksChartData.datasets
          }
        }
        this.revenueOver52WeeksChart = new Chart(
          this.$refs['revenueOver52WeeksChart'],
            {...this.revenueOver52WeeksChartConf});
      }

      if(this.single.hasOwnProperty('missedCallsChartData')) {
        if(this.missedCallsChart) {
          this.missedCallsChart.destroy()
        }
        this.missedCallsChartConf = {
          type: 'line',
          options: {
            plugins: {
              legend: {
                display: false
              }
            }
          },
          data: {
            labels: this.single.missedCallsChartData.labels,
            datasets: this.single.missedCallsChartData.datasets
          }
        }
        this.missedCallsChart = new Chart(
          this.$refs['missedCallsChart'],
            {...this.missedCallsChartConf});
      }

      if(this.single.hasOwnProperty('callsByExtensionChart')) {
        if(this.callsByExtensionChart) {
          this.callsByExtensionChart.destroy()
        }
        this.callsByExtensionChartConf = {
          type: 'line',
          options: {
            plugins: {
              legend: {
                display: false
              },
            },
            // scales: {
            //   'x': {
            //     display: false,
            //   }
            // }
          },
          data: {
            labels: this.single.callsByExtensionChart.labels,
            datasets: this.single.callsByExtensionChart.calls,
          },
        }
        this.callsByExtensionChart = new Chart(
          this.$refs['callsByExtensionChart'],
            {...this.callsByExtensionChartConf});
      }
    }
  },
  computed: {
    ...mapGetters('overview', ['single', 'isLoading'])
  },
  methods: {
    ...mapActions('overview', ['fetch']),
    doFetch() {
      this.fetch({
        from: this.fromDate.format('DD/MM/YYYY'),
        to: this.toDate.format('DD/MM/YYYY'),
      })
    },

    toggleCollapse (name) {
      this.collapsed[name] = !this.collapsed[name]
    },

    calculatePercentageDiff(val1, val2) {
      return Number((val2 - val1) / val1 * 100).toFixed(2);
    }
  },
  filters: {
    currencyFormat: (v) => new Intl.NumberFormat('en-gn', {
        style: 'currency', currency: 'GBP'
    }).format(v),

    
  }
}
</script>

<style lang="scss">

  .content-container {
    overflow: auto;
    position: sticky;
    height: 100%;
  }

  .left-panel {
    border-right: 1px solid #d8d8df;
    overflow-y: auto;
    overflow-x: hidden;

    height: 100%;
    top: 0;

    ul.left-panel-nav {
      list-style: none;
      position: sticky;
      top: 0;

      li.left-panel-nav-header {
        color: lighten($dark, 20%);
        font-size: 0.8rem;
        font-weight: bold;
        padding: 0.5rem 1rem;
        text-transform: uppercase;
      }

      li.left-panel-nav-item {
        text-overflow: ellipsis;
        width: 100%;

        a {
          color: $dark;
          text-overflow: ellipsis;
          white-space: nowrap;
          width: 100%;

          svg {
            color: lighten($dark, 10%);
            margin-top: -.2em;
          }

          &:not(.active):hover {
            background: darken($light, 5%);
          }

          &.active {
            background: $primary;
            color: $light;

            svg {
              color: $light;
            }
          }
        }
      }
    }
  }

  .negate-header-padding {
    height: calc(100vh - 68px);

    .col {
      min-height: 100%;
      max-height: 100%;
    } 
  }

  .primary-nav-tabs {
    margin-left: -15px;
    margin-right: -15px;

    .nav {
      flex-wrap: inherit;
      overflow: auto;
      width: 100%;
      border-bottom: none;

      li:first-of-type .nav-link {
        border-left: none;
        border-top-left-radius: 0;
      }

      li:last-of-type .nav-link {
        border-right: none;
        border-top-right-radius: 0;
      }
    }
    .nav-justified .nav-item, .nav-justified>.nav-link {
        border-bottom: 1px solid #dee2e6;
    }
  }

</style>
