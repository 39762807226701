<template>
    <b-row>
        <b-col md="6" class="mb-2 mb-md-0">
            <div @click="litePicker.show()" ref="lite-picker" class="input form-control d-flex flex-row align-items-center" role="button" @mouseover="startPickerHovered = true" @mouseleave="startPickerHovered = false">
                <b-icon class="input-icon" :icon="startPickerHovered ? 'calendar-fill' : 'calendar'"></b-icon>
                <p class="text-muted mb-0 ml-3" v-if="!startDate">No date selected</p>
                <p class="mb-0 ml-3" v-else>{{ moment(startDate).format('dddd, D MMMM YYYY') }}</p>
            </div>
        </b-col>
        <b-col md="6">
            <div @click="litePicker.show()" class="input form-control d-flex flex-row align-items-center" role="button" @mouseover="endPickerHovered = true" @mouseleave="endPickerHovered = false">
                <b-icon class="input-icon" :icon="endPickerHovered ? 'calendar-fill' : 'calendar'"></b-icon>
                <p class="text-muted mb-0 ml-3" v-if="!endDate">No date selected</p>
                <p class="mb-0 ml-3" v-else>{{ moment(endDate).format('dddd, D MMMM YYYY') }}</p>
            </div>
        </b-col>
    </b-row>
</template>

<script>
import momentMixin from '../mixins/momentMixin'
import LitePicker from 'litepicker'

export default {
    mixins: [momentMixin],
    props: [ 'from', 'to' ],
    data: () => ({
        litePicker: null,

        startDate: null,
        endDate: null,

        startPickerHovered: false,
        endPickerHovered: false,
    }),
    mounted() {
        this.litePicker = new LitePicker({
            element: this.$refs['lite-picker'],
            singleMode: false,
            selectBackward: false,
            splitView: true,
            numberOfMonths: 2,
            numberOfColumns: 2,
            format: 'DD/MM/YYYY',
            setup: picker => {
                picker.on('selected', (date1, date2) => {
                    this.startDate = this.moment(date1.dateInstance)
                    this.endDate = this.moment(date2.dateInstance)
                    this.$emit('input', {
                        'date1': this.startDate,
                        'date2': this.endDate
                    })
                })
            }
        })
        
        this.litePicker.setDateRange(this.from, this.to)
    },
}
</script>

<style>

</style>