<template>
  <b-container fluid>
    <b-row class="mb-4">
      <b-col cols="12">
        <h1 class="font-weight-bold mb-1">Timesheets</h1>
      </b-col>
    </b-row>
    <b-row class="mb-4">
      <b-col cols="12">
        <b-card>
          <b-row>
            <b-col class="d-flex flex-row" cols="12" @click.prevent="toggleCollapse('utilities')" role="button">
              <b-icon class="align-self-center cursor-pointer text-muted" font-scale="1.5" :icon="collapsed['utilities'] ? 'chevron-right' : 'chevron-down'"></b-icon>
              <div class="d-flex flex-column flex-fill ml-4">
                <p class="h4 font-weight-bold mb-0">Utilities</p>
              </div>
            </b-col>
          </b-row>
          <b-row class="mt-4" v-if="!collapsed['utilities']">
            <b-col cols="12">
              <b-row>
                <b-col cols="12" md="6">
                  <b-form-group>
                    <b-form-datepicker v-model="selectedSearchStartDate" @input="getTimesheets"/>
                  </b-form-group>
                </b-col>
                <b-col cols="12" md="6">
                  <b-form-group>
                    <b-form-datepicker v-model="selectedSearchEndDate" @input="getTimesheets"/>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="12">
                  <b-form-group>
                    <user-search type="user" v-model="user" :timesheet-start-date="selectedSearchStartDate" :timesheet-end-date="selectedSearchEndDate" @input="getTimesheets"/>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="12">
                  <hr>
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="12">
                  <b-card>
                    <b-row>
                      <b-col cols="12">
                        <h4 class="mb-3">Log Timesheet</h4>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col cols="12" class="mt-3">
                        <b-row>
                          <b-col cols="12">
                            <b-card>
                              <b-row class="d-flex align-items-center">
                                <b-col class="flex-row flex-fill justify-content-start align-items-center" cols="12" md="7">
                                  <h3 class="mb-0 text-muted"><b>User</b></h3>
                                </b-col>
                                <b-col class="d-flex justify-content-end align-items-center" cols="12" md="5">
                                  <user-search type="user" v-model="newTimesheetUser"/>
                                </b-col>
                              </b-row>
                            </b-card>
                          </b-col>
                        </b-row>
                        <b-row class='mt-3'>
                          <b-col cols="12">
                            <b-form-group label="Date">
                              <b-form-datepicker v-model="newTimesheetDate"/>
                            </b-form-group>
                          </b-col>
                        </b-row>
                        <b-row>
                          <b-col cols="12" md="6">
                            <b-form-group label="Start Time">
                              <b-form-input v-model="newTimesheetStartTime" placeholder="HH:MM"/>
                            </b-form-group>
                          </b-col>
                          <b-col cols="12" md="6">
                            <b-form-group label="End Time">
                              <b-form-input v-model="newTimesheetEndTime" placeholder="HH:MM"/>
                            </b-form-group>
                          </b-col>
                        </b-row>
                        <b-row>
                          <b-col cols="12" class="d-flex flex-fill justify-content-end">
                            <b-button variant="primary" @click="newTimesheet">Submit</b-button>
                          </b-col>
                        </b-row>
                      </b-col>
                    </b-row>
                  </b-card>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>
    <b-row class="mb-4">
      <b-col cols="12">
        <b-row>
          <b-col cols="12" v-if="!computedTimesheetHours" class="p-4">
            <b-row>
              <b-col class="text-center" cols="12">
                <b-card class="py-3 text-center">
                  <p class="mb-4"><fa-icon class="display-1 text-dark" icon="times"></fa-icon></p>
                  <p class="font-weight-normal h5 mb-0 text-dark">There are no timesheets.</p>
                  <small>Select a user and a date range.</small>
                </b-card>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
        <b-table-simple striped  v-if="computedTimesheetHours">

          <b-thead head-variant="dark">
            <b-tr>
              <b-th colspan="1">Date</b-th>
              <b-th colspan="1">Clock In</b-th>
              <b-th colspan="1">Clock Out</b-th>
              <b-th colspan="1">Hours</b-th>
            </b-tr>
          </b-thead>

          <b-tbody>
            <b-tr v-for="(timesheet, index) in timesheets" :key="index">
              <b-th colspan="1">{{ moment(timesheet.date).format('DD/MM/YYYY') }}</b-th>
              <b-th colspan="1">{{ timesheet.clock_in }}</b-th>
              <b-th colspan="1">{{ timesheet.clock_out }}</b-th>
              <b-th colspan="1">{{ computedTimesheetHours }}</b-th>
            </b-tr>
          </b-tbody>

          <b-tfoot>
            <b-tr>
              <b-td></b-td>
              <b-td></b-td>
              <b-th>Total Hours:</b-th>
              <b-th>{{ computedTimesheetHours }}</b-th>
            </b-tr>
          </b-tfoot>
        </b-table-simple>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import FriendlyDate from '../../components/FriendlyDate'
import currentUser from '../../mixins/currentUser'
import { mapActions, mapGetters } from 'vuex'
import momentMixin from '../../mixins/momentMixin'
import UserSearch from '../../components/UserSearch';

export default {
  mixins: [currentUser, momentMixin],
  data () {
    return {
      collapsed: {
        utilities: true
      },

      user: { id: null },
      selectedSearchStartDate: this.moment().toDate(),
      selectedSearchEndDate: this.moment().toDate(),

      newTimesheetUser: { id: null },
      newTimesheetDate: this.moment().toDate(),
      newTimesheetStartTime: null,
      newTimesheetEndTime: null,

      timesheets: [],
    }
  },
  created() {
    this.user.id = this.currentUser.id
    this.newTimesheetUser.id = this.currentUser.id
    this.getTimesheets()
  },
  components: {
    FriendlyDate,
    UserSearch
  },
  computed: {
    ...mapGetters('timesheets', ['data', 'isDeleting', 'isLoading', 'pagination']),
    computedTimesheetHours() {
      if(!this.user.id || !this.timesheets.length) return false;
      return this.timesheets.reduce((a, b) => a + (b['hours'] || 0), 0);
    }
  },
  methods: {
    ...mapActions('timesheets', ['batchDelete', 'fetchMany', 'save']),
    onSearch (query) {
      if (this.$route.query.query !== query) {
        this.$router.push({ name: this.$route.name, query: { ...this.$route.query, query } })
        this.fetchMany({ ...this.$route.query, query })
      }
    },
    getTimesheets() {
      if(this.user.id && this.selectedSearchStartDate && this.selectedSearchEndDate) {
        var self = this;
        this.fetchMany({
          'query': '',
          page: 1,
          extraParams: { 'timesheet_start_date': this.moment(this.selectedSearchStartDate).format('DD-MM-YYYY'),'timesheet_end_date': this.moment(this.selectedSearchEndDate).format('DD-MM-YYYY'), 'user': this.user.id }
        }).then(response => {
          self.timesheets = response.data.data;
        });
      }
    },

    toggleCollapse (name) {
      this.collapsed[name] = !this.collapsed[name]
    },

    newTimesheet() {
      if(this.newTimesheetUser.id && this.newTimesheetDate && this.newTimesheetStartTime && this.newTimesheetEndTime) {
        this.save({
          user_id: this.newTimesheetUser.id,
          date: this.newTimesheetDate,
          start_time: this.newTimesheetStartTime,
          end_time: this.newTimesheetEndTime,
        }).then(response => {
          //
        })
      }
    }
  }
}
</script>

<style>

</style>
